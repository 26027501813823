<script setup>
import { ref, watch, onMounted, watchEffect } from "vue";

const props = defineProps(["teachers", "students", "skills", "apis"]);
const timeZone = ref("");
const gmt = ref("");
const formData = ref({
    class: {
        name: "",
        publish: 1,
        description: "",
        teachers: [],
        students: [],
    },
    lessons: [],
    timezone: "",
});
const teacherList = ref();
const studentList = ref();
const lessonDates = ref();
const lessonDatesElm = ref(null);
const lessonStartTime = ref();
const lessonStart = ref("10:00");
const lessonDuration = ref();
const submitError = ref(null);

onMounted(() => {
    timeZone.value =
        document.documentElement.getAttribute("timezone") ?? "Asia/Taipei";
    gmt.value = document.documentElement.getAttribute("gmt") ?? "+08:00";
    formData.value.timezone = timeZone.value;

    $(teacherList.value)
        .select2({
            selectionCssClass: "form-select p-0 pb-1",
            width: "100%",
        })
        .on("select2:select", (e) => {
            userToggle(formData.value.class.teachers, e.params.data.id);
        })
        .on("select2:unselect", (e) => {
            userToggle(formData.value.class.teachers, e.params.data.id);
        });

    $(studentList.value)
        .select2({
            selectionCssClass: "form-select p-0 pb-1",
            width: "100%",
        })
        .on("select2:select", (e) => {
            userToggle(formData.value.class.students, e.params.data.id);
        })
        .on("select2:unselect", (e) => {
            userToggle(formData.value.class.students, e.params.data.id);
        });

    lessonDatesElm.value = new AirDatepicker(lessonDates.value, {
        locale: AirDatepickerLocaleEn,
        multipleDates: true,
        position: "bottom center",
        dateFormat: "yyyy-MM-dd",
        onSelect: ({ date, formattedDate, datepicker }) => {
            datepicker.$el.value = formattedDate.sort().join(", ");
            updateLessonList(formattedDate);
        },
    });

    new AirDatepicker(lessonStartTime.value, {
        locale: AirDatepickerLocaleEn,
        timepicker: true,
        onlyTimepicker: true,
        position: "bottom center",
        timeFormat: "HH:mm",
        hoursStep: 1,
        minutesStep: 30,
        startDate: `2024-01-01 ${lessonStart.value}`,
        onSelect: ({ date, formattedDate, datepicker }) => {
            lessonStart.value = formattedDate;
        },
    });
});

const userToggle = (collection, item) => {
    var idx = collection.indexOf(item);
    if (idx !== -1) {
        collection.splice(idx, 1);
    } else {
        collection.push(item);
    }
};

const updateLessonList = (dates) => {
    var hadDates = formData.value.lessons?.map((lesson) => {
        return lesson.date;
    });

    var insertArr = dates.sort().filter((item) => !hadDates.includes(item));
    var deleteArr = hadDates.filter((item) => !dates.includes(item));

    insertArr.map((date) => {
        formData.value.lessons.push({
            date: date,
            name: `Lessons`,
            skill: "listening",
            startTime: `${date} ${lessonStart.value}`,
            duration: lessonDuration.value.value,
            description: "",
        });
    });

    deleteArr.map((date) => {
        var index = formData.value.lessons.findIndex(function (lesson) {
            return lesson.date == date;
        });

        formData.value.lessons.splice(index, 1);
    });

    formData.value.lessons.sort(function (a, b) {
        return (
            parseInt(a.date.replaceAll("-", "")) -
            parseInt(b.date.replaceAll("-", ""))
        );
    });

    setTimeout(() => {
        document.querySelectorAll(".form-lesson-start").forEach((item) => {
            new AirDatepicker(item, {
                locale: AirDatepickerLocaleEn,
                position: "bottom center",
                dateFormat: "yyyy-MM-dd",
                timeFormat: "HH:mm",
                hoursStep: 1,
                minutesStep: 30,
                selectedDates: item.value,
                timepicker: true,
                onSelect: ({ date, formattedDate, datepicker }) => {},
            });
        });
    }, 500);
};

const deleteLesson = (event, date) => {
    lessonDatesElm.value.unselectDate(date);
};

const submitForm = (event) => {
    window.loadingModal.show();

    sendData()
        .then((response) => {
            setTimeout(() => {
                window.customModal._element.addEventListener(
                    "show.bs.modal",
                    () => {
                        window.loadingModal.hide();
                    }
                );

                window.customModal._element.querySelector(
                    ".modal-body"
                ).innerHTML = response.data.message;
                window.customModal.show();
                window.customModal._element.addEventListener(
                    "hidden.bs.modal",
                    () => {
                        window.location.href = response.data.redirect_url;
                    }
                );
            }, 500);
        })
        .catch((error) => {
            if (error.response.status == 422 && error.response.data.errors) {
                submitError.value = error.response.data.errors;
                setTimeout(() => {
                    window.customModal._element.addEventListener(
                        "show.bs.modal",
                        () => {
                            window.loadingModal.hide();
                        }
                    );

                    window.customModal._element.querySelector(
                        ".modal-body"
                    ).innerHTML = error.response.data.errors;
                    window.customModal.show();
                }, 500);
            }
        });
};

const sendData = async () => {
    return await axios({
        method: "POST",
        url: props.apis.add,
        data: {
            name: formData.value.class.name,
            publish: formData.value.class.publish,
            description: formData.value.class.description,
            teacher: formData.value.class.teachers.join(","),
            student: formData.value.class.students.join(","),
            timezone: formData.value.timezone,
            lessons: formData.value.lessons,
        },
    });
};
</script>

<template>
    <form class="row g-3" @submit.prevent="submitForm($event)">
        <div class="col-12">
            <label for="class_name" class="form-label">{{
                $t("group_class.form.class_name")
            }}</label>
            <input
                type="text"
                :class="{
                    'form-control': true,
                }"
                v-model="formData.class.name"
                required
            />
            <div class="invalid-feedback"></div>
        </div>
        <div class="col-md-6">
            <label class="form-label">{{
                $t("group_class.form.publish")
            }}</label>
            <select
                :class="{
                    'form-select': true,
                }"
                :v-model="formData.class.publish"
                required
            >
                <option value="1">
                    {{ $t("group_class.menu.publish.1") }}
                </option>
                <option value="0">
                    {{ $t("group_class.menu.publish.0") }}
                </option>
            </select>
            <div class="invalid-feedback"></div>
        </div>
        <div class="col-12">
            <label class="form-label">{{
                $t("group_class.form.description")
            }}</label>
            <textarea
                :class="{
                    'form-control': true,
                }"
                rows="5"
                v-model="formData.class.description"
            ></textarea>
        </div>
        <div class="col-12">
            <label class="form-label">{{
                $t("group_class.form.teachers")
            }}</label>
            <select
                :class="{
                    'w-100': true,
                    'form-select': true,
                }"
                multiple="multiple"
                ref="teacherList"
            >
                <option :value="teacher.uid" v-for="teacher in teachers">
                    {{ teacher.name }}
                </option>
            </select>
        </div>
        <div class="col-12">
            <label class="form-label">{{
                $t("group_class.form.students")
            }}</label>
            <select
                :class="{
                    'w-100': true,
                    'form-select': true,
                }"
                multiple="multiple"
                ref="studentList"
            >
                <option :value="student.uid" v-for="student in students">
                    {{ student.name }}
                </option>
            </select>
        </div>
        <div class="col-12">
            <label class="form-label">{{
                $t("group_class.form.lesson_list")
            }}</label>
            <div class="input-group">
                <input
                    type="text"
                    ref="lessonDates"
                    class="form-control"
                    :placeholder="$t('group_class.placeholder.class_list')"
                    readonly
                />
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="input-group">
                <span class="input-group-text">{{
                    $t("group_class.form.lesson_start")
                }}</span>
                <input
                    type="text"
                    ref="lessonStartTime"
                    class="form-control"
                    :placeholder="$t('group_class.placeholder.class_list')"
                    value="10:00"
                />
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="input-group">
                <span class="input-group-text">{{
                    $t("group_class.form.duration")
                }}</span>
                <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('group_class.placeholder.duration')"
                    aria-label="Duration Time"
                    ref="lessonDuration"
                    value="30"
                    min="30"
                    step="15"
                />
            </div>
        </div>

        <div class="col-12">
            <ul class="list-group">
                <li
                    v-for="(lesson, index) in formData.lessons"
                    class="list-group-item p-0 border-0"
                >
                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <div
                                class="w-100"
                                data-bs-toggle="collapse"
                                :href="`#collapse_${index + 1}`"
                                role="button"
                                aria-expanded="true"
                                :aria-controls="`collapse_${index + 1}`"
                            >
                                <span class="title">
                                    <span class="lesson me-2 fw-bold">{{
                                        index + 1 + "."
                                    }}</span>
                                    <span class="me-1 fw-bold">
                                        {{ lesson.startTime }}
                                    </span>
                                    {{ lesson.name }}
                                </span>
                                <!-- ({{ $t("group_class.form.lesson_duration") }}:
                                <span>{{ lesson.duration }}</span>
                                {{ $t("group_class.form.time_unit") }}) -->
                            </div>
                            <div class="ms-auto">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-danger"
                                    @click="deleteLesson($event, lesson.date)"
                                >
                                    <i class="fa-regular fa-trash-can"></i>
                                </button>
                            </div>
                        </div>
                        <div
                            class="card-body collapse show"
                            :id="`collapse_${index + 1}`"
                        >
                            <div class="row g-3">
                                <div class="col-12">
                                    <label class="form-label">{{
                                        $t("group_class.form.lesson_name")
                                    }}</label>
                                    <input
                                        type="text"
                                        :class="{
                                            'form-control': true,
                                        }"
                                        v-model="lesson.name"
                                        required
                                    />
                                </div>
                                <div class="col-12">
                                    <label class="form-label">{{
                                        $t("group_class.form.lesson_skill")
                                    }}</label>
                                    <select
                                        :class="{
                                            'text-capitalize': true,
                                            'form-control': true,
                                        }"
                                        class="form-control text-capitalize"
                                        v-model="lesson.skill"
                                        required
                                    >
                                        <option
                                            :value="skill"
                                            v-for="skill in props.skills"
                                        >
                                            {{ skill }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label"
                                        >{{
                                            $t("group_class.form.lesson_start")
                                        }}
                                        (GMT{{ gmt }})</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-lesson-start"
                                        v-model="lesson.startTime"
                                        required
                                    />
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label"
                                        >{{
                                            $t(
                                                "group_class.form.lesson_duration"
                                            )
                                        }}
                                        ({{
                                            $t("group_class.form.time_unit")
                                        }})</label
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        v-model="lesson.duration"
                                        min="30"
                                        step="15"
                                        required
                                    />
                                </div>
                                <div class="col-12">
                                    <label class="form-label">{{
                                        $t(
                                            "group_class.form.lesson_description"
                                        )
                                    }}</label>
                                    <textarea
                                        class="form-control"
                                        rows="5"
                                        v-model="lesson.description"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="col-12 text-center">
            <a :href="props.apis.index" class="btn btn-sm btn-danger mx-1">{{
                $t("group_class.button.back")
            }}</a>
            <button type="submit" class="btn btn-sm btn-primary mx-1">
                {{ $t("group_class.button.add") }}
            </button>
        </div>
    </form>
</template>
