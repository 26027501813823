<script setup>
const props = defineProps(["scheduleData", "gmt"]);
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.status") }}:
                </div>
                <div class="me-2 px-2 fw-bold bg-secondary-subtle rounded">
                    <div class="schedule-confirm">
                        {{ $t(`booking.status.${props.scheduleData?.status}`) }}
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.time") }}:
                </div>
                <div class="me-2 fw-bold px-2 bg-secondary-subtle rounded">
                    {{ props.scheduleData.start.slice(0, -3) }}
                </div>
                {{ $t("booking.booking.form.to") }}
                <div class="ms-2 fw-bold px-2 bg-secondary-subtle rounded">
                    {{ props.scheduleData.end.slice(0, -3) }}
                </div>
                <div class="ms-2 fw-bold small">
                    {{ `(GMT${props.gmt})` }}
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.teacher") }}:
                </div>
                <div
                    v-for="teacher in props.scheduleData.data.teachers"
                    :key="teacher"
                    class="me-2 px-2 bg-secondary-subtle rounded"
                >
                    <a :href="teacher.link" target="_blank">{{
                        teacher.name
                    }}</a>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.student") }}:
                </div>
                <div>
                    <div
                        v-for="student in props.scheduleData.data.students"
                        class="me-2 my-1 px-2 bg-secondary-subtle rounded d-inline-block"
                    >
                        {{ student.name }}
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.mode") }}:
                </div>
                <div
                    class="me-2 px-2 bg-secondary-subtle rounded text-capitalize"
                >
                    {{
                        `${props.scheduleData.type} (${props.scheduleData.mode})`
                    }}
                </div>
            </div>
            <div
                v-if="props.scheduleData.status == 3"
                class="d-flex flex-wrap align-items-center mb-2"
            >
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.classroom_link") }}:
                </div>
                <div class="me-2 px-2">
                    <a
                        :href="props.scheduleData.data.classroom"
                        target="_blank"
                        class="btn btn-orange btn-sm"
                        >{{ $t("booking.button.enter_classroom") }}</a
                    >
                </div>
                <small>{{ $t("booking.memo.pre_class") }}</small>
            </div>
            <div
                v-if="props.scheduleData.status == 0"
                class="d-flex flex-wrap align-items-center mb-2"
            >
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.canceller") }}:
                </div>
                <div class="me-2 px-2 bg-secondary-subtle rounded text-danger">
                    {{ props.scheduleData.data.times.cancel.name }}
                </div>
            </div>
            <div
                v-if="props.scheduleData.status == 0"
                class="d-flex flex-wrap align-items-center mb-2"
            >
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.reason") }}:
                </div>
                <div class="me-2 px-2 bg-secondary-subtle rounded">
                    {{ props.scheduleData.data.reason }}
                </div>
            </div>
        </div>
    </div>
</template>
