<script setup>
import axios from "axios";
import {
    onMounted,
    onUnmounted,
    ref,
    watch,
    watchEffect,
    computed,
    defineAsyncComponent,
} from "vue";
import OneOnOneShow from "@/components/schedule/OneOnOneShow.vue";
import OneOnOneForm from "@/components/schedule/OneOnOneForm.vue";
import OneOnOneEmptyForm from "@/components/schedule/OneOnOneEmptyForm.vue";
import GroupClassShow from "@/components/schedule/GroupClassShow.vue";
import GroupClassForm from "@/components/schedule/GroupClassForm.vue";

const props = defineProps([
    "admin_role",
    "book_fee",
    "cancel_before",
    "available_start",
    "available_end",
]);
const timeZone = ref("");
const gmt = ref("");
const scheduleDateUnit = ref("months");
const momentRef = ref(null);
const nowDate = ref(moment().format("YYYY-MM-DD"));
const currentDate = ref(moment().format("YYYY-MM-DD"));
const datesRef = ref([]);
const scheduleTimeTitle = ref();
const scheduleGMTTitle = ref();
const scheduleTimeDurationRef = ref([]);
const scheduleData = ref([]);
const scheduleDataById = ref(null);
const cancellationReason = ref();
const scheduleContentModal = ref();
const scheduleContentAlert = ref();
const editMode = ref(false);
const addMode = ref(false);
const errorMessage = ref(null);

watch(scheduleDateUnit, async (newValue, oldValue) => {
    getDateRange();
});

watch(datesRef, async (newValue, oldValue) => {
    fetchScheduleData();
});

watch(
    scheduleDataById,
    (oldValue, newValue) => {
        errorMessage.value = null;
    },
    {
        deep: true,
    }
);

onMounted(async () => {
    timeZone.value =
        document.documentElement.getAttribute("timezone") ?? "Asia/Taipei";
    gmt.value = document.documentElement.getAttribute("gmt") ?? "+08:00";
    nowDate.value = convertTimezone(moment()).format("YYYY-MM-DD");
    currentDate.value = convertTimezone(moment()).format("YYYY-MM-DD");
    moment.locale(
        document.documentElement.lang === "zh"
            ? "zh-tw"
            : document.documentElement.lang
    );
    modalSetting();
    getDateRange();
    createScheduleTimeDuration();
});

const createScheduleTimeDuration = () => {
    let startTime = moment().startOf("day");
    let endTime = moment().endOf("day");
    while (startTime <= endTime) {
        scheduleTimeDurationRef.value.push(startTime.format("HH:mm"));
        startTime = startTime.add(30, "minutes");
    }
};

const convertTimezone = (datetime) => {
    return datetime.zone(gmt.value);
};

const changeDateUnit = (event) => {
    scheduleDateUnit.value = event.target.dataset.unit;
};

const changeDate = (event) => {
    let mode = event.target.closest(".btn").dataset.mode;

    switch (mode) {
        case "today":
            currentDate.value = convertTimezone(moment()).format("YYYY-MM-DD");
            break;
        case "previous":
            currentDate.value = moment(currentDate.value)
                .subtract(1, scheduleDateUnit.value)
                .format("YYYY-MM-DD");
            break;
        case "next":
            currentDate.value = moment(currentDate.value)
                .add(1, scheduleDateUnit.value)
                .format("YYYY-MM-DD");
            break;
        default:
            currentDate.value = convertTimezone(moment()).format("YYYY-MM-DD");
            break;
    }

    getDateRange();
};

const getDateRange = () => {
    datesRef.value = [];
    let startDate = moment(currentDate.value).startOf(scheduleDateUnit.value);
    let endDate = moment(currentDate.value).endOf(scheduleDateUnit.value);

    if (scheduleDateUnit.value === "months") {
        startDate = moment(startDate).startOf("weeks");
        endDate = moment(endDate).endOf("weeks");
    }

    while (startDate < endDate) {
        datesRef.value.push({
            date: startDate.format("YYYY-MM-DD"),
            display: startDate.format("DD"),
            weekday: startDate.format("ddd"),
        });

        startDate = moment(startDate).add(1, "days");
    }

    displayScheduleTimeTitle();
};

const fetchScheduleData = async (id = null) => {
    let urlParams = new URLSearchParams(location.search);

    await axios({
        method: "post",
        url: location.origin + location.pathname + (id ? `/${id}` : ""),
        timeout: 5000,
        data: {
            start: datesRef.value.at(0).date,
            end: datesRef.value.at(-1).date,
            uid: urlParams.get("uid"),
        },
    })
        .then(function (response) {
            if (id == null) {
                scheduleData.value = response.data;
            } else {
                scheduleDataById.value = response.data;
            }
        })
        .catch(function (error) {});
};

const updateScheduleData = async (id, data) => {
    return await axios({
        method: "put",
        url: location.origin + location.pathname + `/${id}`,
        timeout: 5000,
        data: data,
    });
};

const confirmSchedule = async (event) => {
    event.target.closest(".btn").classList.toggle("disabled");

    await updateScheduleData(scheduleDataById.value.id, {
        confirm: scheduleDataById.value.data.role,
        updateType: "confirm",
    })
        .then(function (response) {
            event.target.closest(".btn").classList.toggle("disabled");
            scheduleDataById.value = response.data.data;
            fetchScheduleData();
        })
        .catch(function (error) {
            event.target.closest(".btn").classList.toggle("disabled");
        });
};

const editScheduleData = async (event) => {
    event.target.closest(".btn").classList.toggle("disabled");

    await updateScheduleData(scheduleDataById.value.id, {
        updateType: "edit",
        timezone: timeZone.value,
        ...scheduleDataById.value,
    })
        .then(function (response) {
            event.target.closest(".btn").classList.toggle("disabled");
            scheduleDataById.value = response.data.data;
            fetchScheduleData();
            editMode.value = false;
        })
        .catch(function (error) {
            event.target.closest(".btn").classList.toggle("disabled");
            errorMessage.value = error.response.data.data[0];
        });
};

const cancelSchedule = async (event) => {
    if (cancellationReason.value.value.length > 2) {
        event.target.closest(".btn").classList.toggle("disabled");

        await updateScheduleData(scheduleDataById.value.id, {
            cancel: scheduleDataById.value.data.role,
            reason: cancellationReason.value.value,
            updateType: "cancel",
        })
            .then(function (response) {
                event.target.closest(".btn").classList.toggle("disabled");
                scheduleDataById.value = response.data.data;
                fetchScheduleData();
                scheduleContentAlert.value.hide();
                cancellationReason.value.classList.remove("is-invalid");
            })
            .catch(function (error) {
                cancellationReason.value.classList.add("is-invalid");
                cancellationReason.value.parentElement.querySelector(
                    ".invalid-feedback"
                ).innerHTML = error.response.data.message;
                event.target.closest(".btn").classList.toggle("disabled");
            });
    } else {
        cancellationReason.value.classList.add("is-invalid");
    }
};

const addSchedule = async () => {
    await axios({
        method: "post",
        url: `${location.origin}${location.pathname}/add`,
        timeout: 5000,
        data: { ...scheduleDataById.value },
    })
        .then((response) => {
            fetchScheduleData();
            scheduleContentModal.value.hide();
        })
        .catch(function (error) {});
};

const displayScheduleTimeTitle = () => {
    let start = datesRef.value.at(0);
    let end = datesRef.value.at(-1);
    let displayDate = start.date;

    switch (scheduleDateUnit.value) {
        case "days":
            displayDate = start.date;
            break;
        case "weeks":
            displayDate = `${start.date} - ${end.date}`;
            break;
        case "months":
            displayDate = moment(currentDate.value).format("YYYY-MM");
            break;
        default:
            displayDate = start.date;
    }

    scheduleTimeTitle.value.innerHTML = displayDate;
    scheduleGMTTitle.value.innerHTML = `(GMT${gmt.value})`;
};

const isSameDate = (currentDatetime, compareDatetime, unit = "days") => {
    return moment(currentDatetime).isSame(compareDatetime, unit);
};

const minutOfDay = (datetime) => {
    let h = parseInt(moment(datetime).format("HH")) * 60;
    let m = parseInt(moment(datetime).format("mm"));

    return h + m + 1;
};

const durationLength = (start, end) => {
    var duration = moment.duration(moment(end).diff(moment(start)));
    var minutes = duration.asMinutes();

    return minutes - 1;
};

const objectValueJoin = (objectData, columnName) => {
    let arr = [];

    objectData.map((item) => {
        arr.push(item[columnName]);
    });

    return arr.join(", ");
};

const modalSetting = () => {
    const scheduleContentElement = document.getElementById(
        "scheduleContentModalToggle"
    );

    scheduleContentModal.value = new bootstrap.Modal(scheduleContentElement, {
        backdrop: "static",
        focus: false,
        keyboard: false,
    });

    scheduleContentElement?.addEventListener("hidden.bs.modal", (event) => {
        editMode.value = false;
        addMode.value = false;
    });

    scheduleContentElement?.addEventListener("show.bs.modal", (event) => {
        if (event.relatedTarget) {
            var currentElement = event.relatedTarget.closest("[data-id]");
            if (currentElement.dataset.id > 0) {
                fetchScheduleData(currentElement.dataset.id);
            } else {
                fetchScheduleData(currentElement.dataset.id);
                addMode.value = true;
            }
        }
    });

    const scheduleContentAlertElement = document.getElementById(
        "scheduleContentAlertModalToggle"
    );

    scheduleContentAlert.value = new bootstrap.Modal(
        scheduleContentAlertElement,
        {
            backdrop: "static",
            focus: false,
            keyboard: false,
        }
    );

    scheduleContentAlertElement?.addEventListener(
        "hidden.bs.modal",
        (event) => {
            event.target
                .querySelector('button[type="submit"]')
                ?.removeEventListener("click", cancelSchedule);
            scheduleContentModal.value.show();
            cancellationReason.value.classList.remove("is-invalid");
        }
    );

    scheduleContentAlertElement?.addEventListener("show.bs.modal", (event) => {
        if (event.relatedTarget) {
            if (event.relatedTarget.dataset.type == "cancel") {
                event.target
                    .querySelector('button[type="submit"]')
                    ?.addEventListener("click", cancelSchedule);
            }
        }
    });
};

const changeMode = () => {
    editMode.value = !editMode.value;
};

const userToggle = (collection, item) => {
    var idx = collection.findIndex((element) => {
        return element.uid == item.uid;
    });

    if (idx !== -1) {
        collection.splice(idx, 1);
    } else {
        collection.push(item);
    }
};
</script>

<template>
    <div class="schedule-element" :data-mode="scheduleDateUnit">
        <div class="row mb-3">
            <div class="col-12">
                <div class="user-schedule">
                    <div class="schedule-control mb-3">
                        <div class="d-flex justify-content-between">
                            <div class="schedule-buttons me-2">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-primary me-2"
                                    data-mode="today"
                                    @click="changeDate($event)"
                                >
                                    {{ $t("booking.button.today") }}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-primary me-2"
                                    data-mode="previous"
                                    @click="changeDate($event)"
                                >
                                    <i class="fa-solid fa-chevron-left"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-primary me-2"
                                    data-mode="next"
                                    @click="changeDate($event)"
                                >
                                    <i class="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                            <div class="my-auto">
                                <div
                                    class="fs-5 fw-bold"
                                    ref="scheduleTimeTitle"
                                ></div>
                                <div
                                    class="small fw-bold text-center"
                                    ref="scheduleGMTTitle"
                                ></div>
                            </div>
                            <div>
                                <div
                                    class="btn-group btn-group-sm"
                                    role="group"
                                >
                                    <button
                                        type="button"
                                        :class="{
                                            btn: true,
                                            'btn-outline-primary': true,
                                            active: scheduleDateUnit == 'days',
                                        }"
                                        data-unit="days"
                                        @click="changeDateUnit($event)"
                                    >
                                        {{ $t("booking.button.day") }}
                                    </button>
                                    <button
                                        type="button"
                                        :class="{
                                            btn: true,
                                            'btn-outline-primary': true,
                                            active: scheduleDateUnit == 'weeks',
                                        }"
                                        data-unit="weeks"
                                        @click="changeDateUnit($event)"
                                    >
                                        {{ $t("booking.button.week") }}
                                    </button>
                                    <button
                                        type="button"
                                        :class="{
                                            btn: true,
                                            'btn-outline-primary': true,
                                            active:
                                                scheduleDateUnit == 'months',
                                        }"
                                        data-unit="months"
                                        @click="changeDateUnit($event)"
                                    >
                                        {{ $t("booking.button.month") }}
                                    </button>
                                </div>
                                <div class="mt-2" v-if="props.admin_role">
                                    <button
                                        type="button"
                                        :class="{
                                            btn: true,
                                            'btn-sm': true,
                                            'btn-outline-primary': true,
                                        }"
                                        data-id="0"
                                        data-bs-target="#scheduleContentModalToggle"
                                        data-bs-toggle="modal"
                                    >
                                        <i class="fa-solid fa-plus"></i>
                                        {{ $t("booking.button.add") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="schedule-main">
                        <div
                            v-if="['days', 'weeks'].includes(scheduleDateUnit)"
                        >
                            <div class="schedule-header schedule-row">
                                <div
                                    v-if="
                                        ['days', 'weeks'].includes(
                                            scheduleDateUnit
                                        )
                                    "
                                    class="schedule-time"
                                >
                                    {{ $t("booking.booking.time") }}
                                </div>
                                <div
                                    v-for="(item, index) in datesRef"
                                    :key="index"
                                >
                                    <div
                                        :class="{
                                            'now-time': item.date == nowDate,
                                        }"
                                    >
                                        <div>{{ item.weekday }}</div>
                                        <div>{{ item.display }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="schedule-body">
                                <div class="schedule-row schedule-container">
                                    <div class="schedule-time"></div>
                                    <div
                                        v-for="(date, index) in datesRef"
                                        :key="index"
                                    >
                                        <div
                                            v-for="item in scheduleData.filter(
                                                (item) =>
                                                    isSameDate(
                                                        date.date,
                                                        item.start,
                                                        'days'
                                                    )
                                            )"
                                            :style="{
                                                '--element-position': `${minutOfDay(
                                                    item.start
                                                )}px`,
                                                '--element-height': `${durationLength(
                                                    item.start,
                                                    item.end
                                                )}px`,
                                            }"
                                            :data-role="item.data.role"
                                            :data-id="item.id"
                                            data-bs-target="#scheduleContentModalToggle"
                                            data-bs-toggle="modal"
                                        >
                                            <div>
                                                <i
                                                    class="fa-solid fa-people-arrows schedule-confirm"
                                                    :data-status="item.status"
                                                ></i>
                                                {{ item.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-for="(
                                        duration, index
                                    ) in scheduleTimeDurationRef"
                                    :key="index"
                                    class="schedule-row"
                                >
                                    <div
                                        class="schedule-time"
                                        :data-duration-time="duration"
                                    ></div>
                                    <div
                                        v-for="(item, index) in datesRef"
                                        :key="index"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="schedule-row">
                                <div
                                    v-for="(date, index) in datesRef"
                                    :key="index"
                                    :class="{
                                        'schedule-col': true,
                                    }"
                                >
                                    <div
                                        :class="{
                                            'schedule-header': true,
                                            'now-time': date.date == nowDate,
                                            'current-month': isSameDate(
                                                currentDate,
                                                date.date,
                                                'months'
                                            ),
                                        }"
                                    >
                                        <div>{{ date.display }}</div>
                                        <small class="m-0 p-0">
                                            ({{ date.weekday }})
                                        </small>
                                    </div>
                                    <div class="schedule-body">
                                        <div
                                            v-for="item in scheduleData.filter(
                                                (item) =>
                                                    isSameDate(
                                                        date.date,
                                                        item.start,
                                                        'days'
                                                    )
                                            )"
                                            :style="{
                                                '--element-position': `${minutOfDay(
                                                    item.start
                                                )}px`,
                                                '--element-height': `${durationLength(
                                                    item.start,
                                                    item.end
                                                )}px`,
                                            }"
                                            :data-role="item.data.role"
                                            :data-id="item.id"
                                            data-bs-target="#scheduleContentModalToggle"
                                            data-bs-toggle="modal"
                                        >
                                            <div>
                                                <i
                                                    class="fa-solid fa-people-arrows schedule-confirm me-1"
                                                    :data-status="item.status"
                                                    v-if="item.status <= 3"
                                                ></i>
                                                <i
                                                    class="fa-solid fa-person-chalkboard schedule-confirm me-1"
                                                    :data-status="item.status"
                                                    v-if="item.status > 3"
                                                ></i>
                                                <!-- <i class="fa-solid fa-users-line"></i> -->
                                                <!-- <i class="fa-solid fa-users-viewfinder"></i> -->
                                                {{ item.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="scheduleContentModalToggle"
            aria-hidden="true"
            aria-labelledby="scheduleContentModalToggleLabel"
            tabindex="-1"
        >
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div v-if="addMode" class="modal-content">
                    <div class="modal-header">
                        <div class="fs-4 fw-bold">
                            {{ $t("booking.booking.form.add.title") }}
                        </div>
                        <div class="ms-auto">
                            <button
                                type="button"
                                class="btn-close btn-sm"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <OneOnOneEmptyForm
                            v-if="scheduleDataById"
                            :gmt="gmt"
                            :scheduleData="scheduleDataById"
                            @user-toggle="userToggle"
                        ></OneOnOneEmptyForm>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary"
                            data-bs-dismiss="modal"
                        >
                            {{ $t("booking.button.cancel") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-primary"
                            @click="addSchedule"
                        >
                            {{ $t("booking.button.insert") }}
                        </button>
                    </div>
                </div>
                <template v-else>
                    <div v-if="scheduleDataById" class="modal-content">
                        <div class="modal-header">
                            <div class="teacher-name fs-4 fw-bold">
                                <div>
                                    <i
                                        class="fa-solid fa-people-arrows schedule-confirm me-2"
                                        :data-status="scheduleDataById?.status"
                                        v-if="scheduleDataById?.status <= 3"
                                    ></i>
                                    <i
                                        class="fa-solid fa-person-chalkboard schedule-confirm me-2"
                                        :data-status="scheduleDataById?.status"
                                        v-if="scheduleDataById?.status > 3"
                                    ></i>
                                    {{ scheduleDataById?.title }}
                                </div>
                            </div>
                            <div class="ms-auto">
                                <button
                                    type="button"
                                    class="btn-close btn-sm"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <template v-if="scheduleDataById.type === '1on1'">
                                <OneOnOneForm
                                    v-if="editMode && scheduleDataById.editRole"
                                    :scheduleData="scheduleDataById"
                                    :gmt="gmt"
                                ></OneOnOneForm>

                                <OneOnOneShow
                                    v-else
                                    :scheduleData="scheduleDataById"
                                    :gmt="gmt"
                                ></OneOnOneShow>
                            </template>

                            <template
                                v-if="scheduleDataById.type === 'group_class'"
                            >
                                <GroupClassForm
                                    v-if="editMode && scheduleDataById.editRole"
                                    :scheduleData="scheduleDataById"
                                    :gmt="gmt"
                                    @user-toggle="userToggle"
                                ></GroupClassForm>

                                <GroupClassShow
                                    v-else
                                    :scheduleData="scheduleDataById"
                                    :gmt="gmt"
                                ></GroupClassShow>
                            </template>
                        </div>
                        <div class="modal-footer">
                            <template v-if="editMode">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-secondary"
                                    @click="changeMode()"
                                >
                                    {{ $t("booking.button.cancel") }}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-primary"
                                    @click="editScheduleData"
                                >
                                    {{ $t("booking.button.update") }}
                                </button>
                                <div v-if="errorMessage" class="w-100 text-end">
                                    <small class="text-danger">{{
                                        errorMessage
                                    }}</small>
                                </div>
                            </template>
                            <template v-else>
                                <button
                                    v-if="
                                        scheduleDataById.editRole && !editMode
                                    "
                                    type="button"
                                    class="btn btn-outline-primary btn-sm"
                                    @click="changeMode()"
                                >
                                    {{ $t("booking.button.edit") }}
                                </button>
                                <button
                                    v-if="
                                        scheduleDataById.status == '3' &&
                                        scheduleDataById.type == '1on1'
                                    "
                                    type="button"
                                    class="btn btn-sm btn-outline-danger"
                                    data-bs-target="#scheduleContentAlertModalToggle"
                                    data-bs-toggle="modal"
                                    data-type="cancel"
                                >
                                    {{ $t("booking.button.cancel_schedule") }}
                                </button>
                                <button
                                    v-if="
                                        scheduleDataById?.data?.role ===
                                            'teacher' &&
                                        scheduleDataById.status == 1
                                    "
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="confirmSchedule($event)"
                                >
                                    {{ $t("booking.button.confirm") }}
                                </button>
                                <button
                                    v-if="
                                        scheduleDataById?.data?.role ===
                                            'student' &&
                                        scheduleDataById.status == 2
                                    "
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="confirmSchedule($event)"
                                >
                                    {{ $t("booking.button.confirm") }}
                                </button>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div
            v-if="!addMode"
            class="modal fade"
            id="scheduleContentAlertModalToggle"
            aria-hidden="true"
            aria-labelledby="scheduleContentAlertModalToggleLabel"
            tabindex="-1"
        >
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">
                            {{ $t("booking.button.cancel_schedule") }}
                        </h1>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <label class="form-label">{{
                                $t("booking.booking.form.reason")
                            }}</label>
                            <textarea
                                class="form-control"
                                rows="3"
                                ref="cancellationReason"
                                required
                            ></textarea>
                            <div class="invalid-feedback"></div>
                        </div>
                        <div
                            v-if="scheduleDataById?.data?.role === 'student'"
                            class="d-flex flex-column text-danger"
                            v-html="
                                $t('booking.cencel.roles.student', {
                                    book_fee: props.book_fee,
                                    cancel_before: props.cancel_before,
                                })
                            "
                        ></div>
                        <div
                            v-if="scheduleDataById?.data?.role === 'teacher'"
                            class="d-flex flex-column text-danger"
                            v-html="$t('booking.cencel.roles.teacher')"
                        ></div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-sm btn-primary">
                            {{ $t("booking.button.submit") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.schedule-element {
    color: #3f3f3f;
    user-select: none;

    .schedule-row {
        display: grid;

        div {
            word-wrap: break-word;
            word-break: normal;
        }
    }

    .schedule-confirm {
        background: linear-gradient(
            to right,
            var(--bs-success) 0%,
            var(--bs-success) 50%,
            var(--bs-success) 50%,
            var(--bs-success) 100%
        );
        background-clip: text;
        color: transparent;

        // 0 => 取消預約
        // 1 => 等待老師確認
        // 2 => 老師改期，等待學生確認
        // 3 => 雙方已確認時間
        // 4 => 上課中
        // 5 => 上課結束
        &[data-status="0"],
        &[data-status="5"] {
            background: linear-gradient(
                to right,
                var(--bs-gray) 0%,
                var(--bs-gray) 50%,
                var(--bs-gray) 50%,
                var(--bs-gray) 100%
            );
            background-clip: text;
            color: transparent;
        }

        &[data-status="1"] {
            background: linear-gradient(
                to right,
                var(--bs-success) 0%,
                var(--bs-success) 50%,
                var(--bs-red) 50%,
                var(--bs-red) 100%
            );
            background-clip: text;
            color: transparent;
        }

        &[data-status="2"] {
            background: linear-gradient(
                to right,
                var(--bs-red) 0%,
                var(--bs-red) 50%,
                var(--bs-success) 50%,
                var(--bs-success) 100%
            );
            background-clip: text;
            color: transparent;
        }
    }

    &[data-mode="days"] {
        .schedule-row {
            grid-template-columns: 47px 1fr;
            margin-top: -1px;

            &:not(.schedule-header) {
                grid-template-rows: 30px;
            }
        }
    }

    &[data-mode="weeks"] {
        .schedule-row {
            grid-template-columns: 47px repeat(7, minmax(50px, 1fr));
            margin-top: -1px;

            &:not(.schedule-header) {
                grid-template-rows: 30px;
            }
        }
    }

    &[data-mode="days"],
    &[data-mode="weeks"] {
        .schedule-row {
            > div:not(.schedule-time) {
                border-left: 1px solid;
            }
        }

        .schedule-header {
            border-bottom: 1px solid var(--bs-tertiary-color);
            align-items: end;
            font-size: 14px;

            > div:not(.schedule-time) {
                > div {
                    border: 1px solid;
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin: 5px;
                    font-weight: bold;

                    &.now-time {
                        border-color: var(--color-blue-1);
                        border-width: 2px;
                        color: var(--color-blue-1);
                    }

                    > div:nth-of-type(2) {
                        font-size: 16px;
                    }
                }
            }
        }

        .schedule-body {
            position: relative;
            height: 75vh;
            overflow-y: auto;

            .schedule-row {
                &:not(.schedule-container) {
                    border-bottom: 1px solid var(--bs-tertiary-color);
                }

                &.schedule-container {
                    position: absolute;
                    background-color: transparent;
                    grid-template-rows: auto;
                    width: 100%;
                    height: 100%;

                    > div {
                        position: relative;

                        > div {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            position: absolute;
                            top: var(--element-position);
                            height: var(--element-height);
                            width: calc(100% - 0%);
                            max-width: 100%;
                            border: 1px solid #ffffff;
                            border-radius: 4px;
                            color: #ffffff;
                            padding: 2px 5px;
                            cursor: pointer;

                            &[data-role] {
                                background: var(--bs-blue);
                            }

                            &[data-role="teacher"] {
                                background: #4bbcf3;
                            }

                            &[data-role="student"] {
                                background: var(--color-orange-3);
                            }
                        }
                    }
                }

                .schedule-time {
                    &:not([data-duration-time="00:00"]) {
                        position: relative;

                        &::before {
                            content: attr(data-duration-time);
                            display: block;
                            position: absolute;
                            bottom: calc(100% - 10px);
                            background-color: white;
                            width: 100%;
                            text-align: justify;
                            font-size: 14px;
                        }

                        margin-right: 5px;
                    }
                }
            }
        }
    }

    &[data-mode="months"] {
        .schedule-row {
            grid-template-columns: repeat(7, minmax(50px, 1fr));
            border: 1px solid var(--bs-tertiary-color);

            .schedule-header {
                display: flex;
                align-items: center;
                border-width: 2px;
                border-bottom: solid;
                border-color: transparent;

                &:not(.current-month) {
                    color: var(--bs-gray-400);
                }

                > div {
                    display: inline-block;

                    &:nth-of-type(1) {
                        font-weight: bold;
                    }
                }

                &.now-time {
                    border-color: var(--color-blue-1);
                    color: var(--color-blue-1);
                }
            }

            .schedule-header,
            .schedule-body {
                padding-left: 5px;
                padding-right: 5px;
            }

            .schedule-col {
                min-height: 150px;
                height: 100%;
                max-height: auto;
                border: 1px solid var(--bs-tertiary-color);
            }

            .schedule-body {
                > div {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: calc(100% - 0%);
                    max-width: 100%;
                    border: 1px solid #ffffff;
                    border-radius: 4px;
                    color: #ffffff;
                    padding: 2px 5px;
                    cursor: pointer;

                    &[data-role] {
                        background: var(--bs-blue);
                    }

                    &[data-role="teacher"] {
                        background: #4bbcf3;
                    }

                    &[data-role="student"] {
                        background: var(--color-orange-3);
                    }
                }
            }
        }
    }

    .modal-header {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
</style>
