<script setup>
import { ref, watch, onMounted, watchEffect } from "vue";

const emit = defineEmits(["userToggle"]);
const props = defineProps(["gmt", "scheduleData"]);
const nowDate = ref(moment().format("YYYY-MM-DD hh:00"));
const formLessonStart = ref();
const teacherList = ref();
const studentList = ref();
const scheduleStatus = ref({
    teacher: {
        status: "",
        message: "",
    },
    student: {
        status: "",
        message: "",
    },
});
const timeZone = ref("");

watch(
    props.scheduleData,
    (value, oldValue) => {
        calculateChangeTotal();
    },
    {
        deep: true,
    }
);

onMounted(() => {
    timeZone.value =
        document.documentElement.getAttribute("timezone") ?? "Asia/Taipei";

    moment.locale(
        document.documentElement.lang === "zh"
            ? "zh-tw"
            : document.documentElement.lang
    );
    nowDate.value = convertTimezone(moment()).format("YYYY-MM-DD HH:00");

    formLessonStart.value = new AirDatepicker(formLessonStart.value, {
        locale: AirDatepickerLocaleEn,
        multipleDates: false,
        position: "bottom center",
        dateFormat: "yyyy-MM-dd",
        timepicker: true,
        timeFormat: "HH:mm",
        classes: "airDatepicker",
        autoClose: false,
        container: "#scheduleContentModalToggle",
        hoursStep: 1,
        minutesStep: 30,
        minDate: nowDate.value,
        selectedDates: nowDate.value,
        onSelect: ({ date, formattedDate, datepicker }) => {
            props.scheduleData.start = formattedDate;
        },
        onHide: (isFinished) => {
            if (isFinished) {
                getHasSchedule();
            }
        },
    });
});

const convertTimezone = (datetime) => {
    return datetime.zone(props.gmt);
};

const fetchTeacher = async (uid) => {
    await axios({
        method: "post",
        url: `${location.origin}${location.pathname}/teacher`,
        timeout: 5000,
        data: {
            start: props.scheduleData.start,
            duration: props.scheduleData.duration,
            uid: uid,
            timezone: timeZone.value,
        },
    })
        .then(function (response) {
            props.scheduleData.priceData.price = response.data.price;
            scheduleStatus.value.teacher.status = response.data.hasSchedules;
        })
        .catch(function (error) {});
};

const fetchStudent = async (uid) => {
    await axios({
        method: "post",
        url: `${location.origin}${location.pathname}/student`,
        timeout: 5000,
        data: {
            start: props.scheduleData.start,
            duration: props.scheduleData.duration,
            uid: uid,
            timezone: timeZone.value,
        },
    })
        .then(function (response) {
            props.scheduleData.studentPoint = response.data.points;
            scheduleStatus.value.student.status = response.data.hasSchedules;
        })
        .catch(function (error) {});
};

const selectedTeacher = (event) => {
    if (event.target.value > 0) {
        var selectedOption = event.target.selectedOptions[0];
        props.scheduleData.data.teachers = [
            {
                uid: parseInt(selectedOption.value),
                name: selectedOption.textContent,
                level: selectedOption.dataset.level,
            },
        ];
        fetchTeacher(selectedOption.value);
    } else {
        props.scheduleData.priceData.price = 0;
        props.scheduleData.data.teachers = [];
    }
};

const selectedStudent = (event) => {
    if (event.target.value > 0) {
        var selectedOption = event.target.selectedOptions[0];
        props.scheduleData.data.students = [
            {
                uid: parseInt(selectedOption.value),
                name: selectedOption.textContent,
            },
        ];
        fetchStudent(selectedOption.value);
    } else {
        props.scheduleData.data.students = [];
    }
};

const getHasSchedule = () => {
    if (props.scheduleData.data.teachers.length) {
        fetchTeacher(props.scheduleData.data.teachers[0].uid);
    }

    if (props.scheduleData.data.students.length) {
        fetchStudent(props.scheduleData.data.students[0].uid);
    }
};

const calculateChangeTotal = () => {
    props.scheduleData.priceData.lesson = props.scheduleData.duration / 30;
    props.scheduleData.priceData.total =
        props.scheduleData.priceData.lesson *
        props.scheduleData.priceData.price;
};

const nubmerFormat = (number) => {
    return new Intl.NumberFormat().format(number);
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.time") }}:
                </div>
                <div class="me-2 fw-bold px-2 bg-danger-subtle rounded">
                    <input
                        type="text"
                        ref="formLessonStart"
                        class="form-control form-control-sm form-lesson-start text-dark"
                        readonly
                        required
                    />
                </div>
                <div class="ms-2 fw-bold small">
                    {{ `(GMT${props.gmt})` }}
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.duration") }}:
                </div>
                <div class="ms-2 fw-bold px-2 bg-danger-subtle rounded">
                    <input
                        type="number"
                        class="form-control form-control-sm text-dark"
                        min="30"
                        step="30"
                        v-model="props.scheduleData.duration"
                        @change="
                            calculateChangeTotal();
                            getHasSchedule();
                        "
                        required
                    />
                </div>
                <span class="fw-bold">{{ $t("booking.time.type") }}</span>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.teacher") }}:
                </div>
                <div class="me-2 px-2 bg-danger-subtle rounded">
                    <select
                        class="form-select form-select-sm text-dark"
                        ref="teacherList"
                        @change="selectedTeacher($event)"
                    >
                        <option value="" data-level="">
                            {{ $t("booking.booking.form.add.select_teacher") }}
                        </option>
                        <option
                            :value="teacher.uid"
                            :data-level="teacher.level"
                            v-for="teacher in props.scheduleData.teachers"
                            :key="teacher.uid"
                        >
                            {{ teacher.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.student") }}:
                </div>
                <div class="me-2 px-2 bg-danger-subtle rounded">
                    <select
                        class="form-select form-select-sm text-dark"
                        ref="studentList"
                        @change="selectedStudent($event)"
                    >
                        <option value="">
                            {{ $t("booking.booking.form.add.select_teacher") }}
                        </option>
                        <option
                            :value="student.uid"
                            v-for="student in props.scheduleData.students"
                            :key="student.uid"
                        >
                            {{ student.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="row w-100">
                    <div class="col-12 col-md-6 mt-2">
                        <div class="w-100 h-100 border border-3 rounded p-2">
                            <div class="fw-bold">
                                {{
                                    $t("booking.booking.form.add.info_teacher")
                                }}
                            </div>
                            <div>
                                {{ $t("booking.booking.form.add.name") }}:
                                {{
                                    props.scheduleData.data.teachers.length
                                        ? `${
                                              props.scheduleData.data
                                                  .teachers[0].name
                                          } (${
                                              props.scheduleData.data.teachers
                                                  .length
                                                  ? props.scheduleData.data
                                                        .teachers[0].level
                                                  : ""
                                          })`
                                        : ""
                                }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.point") }}:
                                {{
                                    `${nubmerFormat(
                                        props.scheduleData.priceData.price
                                    )} / ${$t("booking.point_area.lesson")}`
                                }}
                            </div>
                            <div>
                                {{
                                    $t(
                                        "booking.booking.form.add.schedule_status"
                                    )
                                }}:
                                <template
                                    v-if="
                                        props.scheduleData.data.teachers.length
                                    "
                                >
                                    <span
                                        class="text-danger fw-bold"
                                        v-if="scheduleStatus.teacher.status > 0"
                                        >{{
                                            $t(
                                                "booking.booking.form.add.cannot_schedule"
                                            )
                                        }}</span
                                    >
                                    <span
                                        class="text-success fw-bold"
                                        v-if="
                                            scheduleStatus.teacher.status == 0
                                        "
                                        >{{
                                            $t(
                                                "booking.booking.form.add.can_schedule"
                                            )
                                        }}</span
                                    >
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mt-2">
                        <div class="w-100 h-100 border border-3 rounded p-2">
                            <div class="fw-bold">
                                {{
                                    $t("booking.booking.form.add.info_student")
                                }}
                            </div>
                            <div>
                                {{ $t("booking.booking.form.add.name") }}:
                                {{
                                    props.scheduleData.data.students.length
                                        ? props.scheduleData.data.students[0]
                                              .name
                                        : ""
                                }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.student.points") }}:
                                {{
                                    nubmerFormat(
                                        props.scheduleData.studentPoint
                                    )
                                }}
                            </div>
                            <div>
                                {{
                                    $t(
                                        "booking.booking.form.add.schedule_status"
                                    )
                                }}:
                                <template
                                    v-if="
                                        props.scheduleData.data.students.length
                                    "
                                >
                                    <span
                                        class="text-danger fw-bold"
                                        v-if="scheduleStatus.student.status > 0"
                                        >{{
                                            $t(
                                                "booking.booking.form.add.cannot_schedule"
                                            )
                                        }}</span
                                    >
                                    <span
                                        class="text-success fw-bold"
                                        v-if="
                                            scheduleStatus.student.status == 0
                                        "
                                        >{{
                                            $t(
                                                "booking.booking.form.add.can_schedule"
                                            )
                                        }}</span
                                    >
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="w-100 h-100 border border-3 rounded p-2">
                            <div class="fw-bold">
                                {{ $t("booking.booking.form.add.info_point") }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.lesson") }}:
                                {{
                                    nubmerFormat(
                                        props.scheduleData.priceData.lesson
                                    )
                                }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.total") }}:
                                {{
                                    nubmerFormat(
                                        props.scheduleData.priceData.total
                                    )
                                }}

                                <span
                                    class="text-danger fw-bold mx-1"
                                    v-if="
                                        props.scheduleData.data.students.length
                                    "
                                >
                                    {{
                                        props.scheduleData.studentPoint -
                                            props.scheduleData.priceData.total <
                                        0
                                            ? $t(
                                                  "booking.point_area.student.point_not_enough"
                                              )
                                            : ""
                                    }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
