<script setup>
import { ref, watch, onMounted, watchEffect } from "vue";

const emit = defineEmits(["userToggle"]);
const props = defineProps(["scheduleData", "gmt"]);
const scheduleForm = ref();
const formLessonStart = ref();
const teacherList = ref();
const studentList = ref();

onMounted(() => {
    formLessonStart.value = new AirDatepicker(formLessonStart.value, {
        locale: AirDatepickerLocaleEn,
        multipleDates: false,
        position: "bottom center",
        dateFormat: "yyyy-MM-dd",
        timepicker: true,
        timeFormat: "HH:mm",
        classes: "airDatepicker",
        container: "#scheduleContentModalToggle",
        hoursStep: 1,
        minutesStep: 30,
        onSelect: ({ date, formattedDate, datepicker }) => {
            props.scheduleData.start = formattedDate + ":00";
        },
        selectedDates: props.scheduleData.start,
    });

    $(studentList.value)
        .select2({
            selectionCssClass: "form-select p-0 pb-1",
            width: "100%",
            dropdownParent: document.querySelector(
                "#scheduleContentModalToggle"
            ),
        })
        .val(
            props.scheduleData.data.students.map((student) => {
                return student.uid;
            })
        )
        .trigger("change")
        .on("select2:select", (e) => {
            emit("userToggle", props.scheduleData.data.students, {
                uid: parseInt(e.params.data.id),
                name: e.params.data.text,
            });
        })
        .on("select2:unselect", (e) => {
            emit("userToggle", props.scheduleData.data.students, {
                uid: parseInt(e.params.data.id),
                name: e.params.data.text,
            });
        });

    $(teacherList.value)
        .select2({
            selectionCssClass: "form-select p-0 pb-1",
            width: "100%",
            dropdownParent: document.querySelector(
                "#scheduleContentModalToggle"
            ),
        })
        .val(
            props.scheduleData.data.teachers.map((teacher) => {
                return teacher.uid;
            })
        )
        .trigger("change")
        .on("select2:select", (e) => {
            emit("userToggle", props.scheduleData.data.teachers, {
                uid: parseInt(e.params.data.id),
                name: e.params.data.text,
            });
        })
        .on("select2:unselect", (e) => {
            emit("userToggle", props.scheduleData.data.teachers, {
                uid: parseInt(e.params.data.id),
                name: e.params.data.text,
            });
        });
});
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.status") }}:
                </div>
                <div class="me-2 px-2 fw-bold bg-secondary-subtle rounded">
                    <div class="schedule-confirm">
                        {{ $t(`booking.status.${props.scheduleData?.status}`) }}
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.time") }}:
                </div>
                <div class="me-2 fw-bold px-2 bg-danger-subtle rounded">
                    <input
                        type="text"
                        ref="formLessonStart"
                        class="form-control form-control-sm form-lesson-start text-dark"
                        required
                    />
                </div>
                <div class="ms-2 fw-bold small">
                    {{ `(GMT${props.gmt})` }}
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.duration") }}:
                </div>
                <div class="ms-2 fw-bold px-2 bg-danger-subtle rounded">
                    <input
                        type="number"
                        class="form-control form-control-sm text-dark"
                        v-model="props.scheduleData.duration"
                        min="30"
                        step="30"
                        required
                    />
                </div>
                <span class="fw-bold">{{ $t("booking.time.type") }}</span>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.teacher") }}:
                </div>
                <div class="me-2 px-2 bg-danger-subtle rounded w-100">
                    <select
                        class="w-100 form-select"
                        multiple="multiple"
                        ref="teacherList"
                    >
                        <option
                            :value="teacher.uid"
                            v-for="teacher in props.scheduleData.teachers"
                            :key="teacher.uid"
                        >
                            {{ teacher.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.student") }}:
                </div>
                <div class="me-2 px-2 bg-danger-subtle rounded w-100">
                    <select
                        class="w-100 form-select"
                        multiple="multiple"
                        ref="studentList"
                    >
                        <option
                            :value="student.uid"
                            v-for="student in props.scheduleData.students"
                            :key="student.uid"
                        >
                            {{ student.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.mode") }}:
                </div>
                <div
                    class="me-2 px-2 bg-secondary-subtle rounded text-capitalize"
                >
                    {{
                        `${props.scheduleData.type} (${props.scheduleData.mode})`
                    }}
                </div>
            </div>
            <div
                v-if="props.scheduleData.status == 0"
                class="d-flex flex-wrap align-items-center mb-2"
            >
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.canceller") }}:
                </div>
                <div class="me-2 px-2 bg-secondary-subtle rounded text-danger">
                    {{ props.scheduleData.data.times.cancel.name }}
                </div>
            </div>
            <div
                v-if="props.scheduleData.status == 0"
                class="d-flex flex-wrap align-items-center mb-2"
            >
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.reason") }}:
                </div>
                <div class="me-2 px-2 bg-secondary-subtle rounded">
                    {{ props.scheduleData.data.reason }}
                </div>
            </div>
        </div>
    </div>
</template>
