import "bootstrap";
import "./_bootstrap";

import "laravel-datatables-vite";
import select2 from "select2";

select2();

import.meta.glob(["../icons/**"]);
import { createApp } from "vue";
import TeachersTimes from "@/components/TeachersTimes.vue";
import UserSchedules from "@/components/UserSchedules.vue";
import SettingSchedules from "@/components/SettingSchedules.vue";
import TeacherDashboard from "@/components/TeacherDashboard.vue";
import AllTeacherDashboard from "@/components/AllTeacherDashboard.vue";
import AddClassForm from "@/components/classes/AddClassForm.vue";
import EditClassForm from "@/components/classes/EditClassForm.vue";
import { i18nVue } from "laravel-vue-i18n";

window.vueApp = createApp({})
    .component("TeacherDashboard", TeacherDashboard)
    .component("AllTeacherDashboard", AllTeacherDashboard)
    .component("TeachersTimes", TeachersTimes)
    .component("UserSchedules", UserSchedules)
    .component("SettingSchedules", SettingSchedules)
    .component("AddClassForm", AddClassForm)
    .component("EditClassForm", EditClassForm)
    .use(i18nVue, {
        resolve: async (lang) => {
            const langs = import.meta.glob("../../public/lang/*.json");
            return await langs[`../../public/lang/${lang}.json`]();
        },
    })
    .mount("#app");

window.postDataVue = (eventType, data) => {
    document.dispatchEvent(
        new CustomEvent("PostDataVueEvent", {
            detail: {
                type: eventType,
                data: data,
            },
        })
    );
};
