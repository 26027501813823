<script setup>
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import "https://d3js.org/d3.v7.min.js";

const data = ref([]);
const teacherSelected = ref(0);
const teacherOptions = ref([]);
const selectMonth = ref();
const selectDate = ref("");
const lists = ref([]);
const props = defineProps(["uid"]);

onMounted(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    selectDate.value = `${year}-${month}`;

    teacherOptions.value.unshift({ name: "All Teacher", uid: 0 });

    new AirDatepicker(selectMonth.value, {
        locale: AirDatepickerLocaleEn,
        view: "months",
        minView: "months",
        autoClose: true,
        dateFormat: "yyyy-MM",
        selectedDates: new Date(),
        onSelect({ formattedDate }) {
            selectDate.value = formattedDate;
        },
    });

    d3.select(window).on("resize", drawBarChart);
});

watch(selectDate, () => {
    getScheduleData()
        .then((repsonse) => {
            teacherOptions.value = repsonse.data.teachers;
            data.value = repsonse.data.report;
            lists.value = repsonse.data.data;
        })
        .catch(() => {})
        .finally(() => {
            toggleLoadingModal("hide");
        });
});

watch(teacherSelected, () => {
    getScheduleData()
        .then((repsonse) => {
            data.value = repsonse.data.report;
            lists.value = repsonse.data.data;
        })
        .catch(() => {})
        .finally(() => {
            toggleLoadingModal("hide");
        });
});

watch(teacherOptions, () => {
    teacherOptions.value.unshift({ name: "All Teacher", uid: 0 });
});

watch(data, () => {
    get_max();
    drawBarChart();
});

const toggleLoadingModal = (action) => {
    if (typeof window.loadingModal._element != "undefined") {
        if (action == "show") {
            window.loadingModal.show();
        }

        if (action == "hide") {
            setTimeout(() => {
                window.loadingModal.hide();
            }, 500);
        }
    }
};

let max = 0;
function get_max() {
    data.value.forEach((item) => {
        if (item.total > max) {
            max = item.total;
        }
    });
}
// RWD
function drawBarChart() {
    // 刪除原本的svg.charts，重新渲染改變寬度的svg
    d3.select(".chart svg").remove();

    // RWD 的svg 寬高
    const rwdSvgWidth = parseInt(d3.select(".chart").style("width")),
        rwdSvgHeight = rwdSvgWidth / 3,
        margin = 20,
        marginBottom = 100;

    const svg = d3
        .select(".chart")
        .append("svg")
        .attr("width", rwdSvgWidth)
        .attr("height", rwdSvgHeight);

    // map 資料集
    const xData = data.value.map((i) => i["date"].substring(8));
    const subgroups = Object.keys(data.value[0]).slice(1);

    // 設定要給 X 軸用的 scale 跟 axis
    const xScale = d3
        .scaleBand()
        .domain(xData)
        .range([margin * 2, rwdSvgWidth - margin]) // 寬度
        .padding(0.2);

    const xAxis = d3.axisBottom(xScale);

    // 呼叫繪製x軸、調整x軸位置
    const xAxisGroup = svg
        .append("g")
        .call(xAxis)
        .style("font-size", "14px")
        .attr("transform", `translate(0,${rwdSvgHeight - marginBottom})`);

    // transform: rotate(-40deg) translate(-20px, 0px);
    // 設定要給 Y 軸用的 scale 跟 axis
    const yScale = d3
        .scaleLinear()
        .domain([0, max + 5])
        .range([rwdSvgHeight - marginBottom, margin]) // 數值要顛倒，才會從低往高排
        .nice(); // 補上終點值

    const yAxis = d3.axisLeft(yScale).ticks(5).tickSize(3);

    // 呼叫繪製y軸、調整y軸位置
    const yAxisGroup = svg
        .append("g")
        .call(yAxis)
        .style("font-size", "14px")
        .attr("transform", `translate(${margin * 2},0)`);

    // 第二條X軸的比例尺，用來設定多條bar的位置
    const xSubgroup = d3
        .scaleBand()
        .domain(subgroups)
        .range([0, xScale.bandwidth()])
        .padding([0.05]);

    // 設定不同 subgorup bar的顏色
    const color = d3
        .scaleOrdinal()
        .domain(subgroups)
        .range(["#0080FF", "#4daf4a", "#FF7575", "#f29909"]);

    // 開始建立長條圖
    const bar = svg
        .append("g")
        .selectAll("g")
        .data(data.value)
        .join("g")
        .attr(
            "transform",
            (d) => `translate(${xScale(d["date"].substring(8))}, 0)`
        )
        .selectAll("rect")
        .data((d) => {
            return subgroups.map((key) => {
                // console.log({key: key, value: d[key]})
                return { key: key, value: d[key] };
            });
        })
        .join("rect")
        .attr("x", (d) => xSubgroup(d.key)) // -- 每條長條圖的間距
        .attr("y", (d) => yScale(d.value))
        .attr("width", xSubgroup.bandwidth()) // -- 長條圖線條的寬度
        .attr("height", (d) => {
            return rwdSvgHeight - marginBottom - yScale(d.value);
        })
        .attr("fill", (d) => color(d.key))
        .style("cursor", "pointer")
        .on("mouseover", handleMouseOver)
        .on("mouseleave", handleMouseLeave);

    function handleMouseOver(d, i) {
        const pt = d3.pointer(d, svg.node());

        // 加上文字標籤
        svg.append("text")
            .attr("class", "infoText")
            .attr("y", yScale(d.target.__data__["value"]) - 5)
            .attr("x", margin * 2 + 5)
            .style("fill", color(i.key))
            .style("font-size", "14px")
            .style("text-transform", "capitalize")
            .text(`${i.key} ${d.target.__data__["value"]}`);

        // 加上軸線
        svg.append("line")
            .attr("class", "dashed-Y")
            .attr("x1", margin * 2)
            .attr("y1", yScale(d.target.__data__["value"]))
            .attr("x2", pt[0])
            .attr("y2", yScale(d.target.__data__["value"]))
            .style("stroke", "black")
            .style("stroke-dasharray", "3");
    }

    function handleMouseLeave() {
        // 移除文字、軸線標籤
        svg.select(".infoText").remove();
        svg.select(".dashed-Y").remove();
    }

    // 加上辨識標籤
    const tagsWrap = svg
        .append("g")
        .selectAll("g")
        .attr("class", "tags")
        .data(subgroups)
        .enter()
        .append("g");

    if (window.innerWidth < 780) {
        tagsWrap.attr("transform", "translate(-70,0)");
    }

    tagsWrap
        .append("rect")
        .attr("x", (d, i) => (i + 1) * marginBottom * 1.3)
        .attr("y", rwdSvgHeight - marginBottom / 2)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", (d) => color(d));

    tagsWrap
        .append("text")
        .attr("x", (d, i) => (i + 1) * marginBottom * 1.3)
        .attr("y", rwdSvgHeight - 10)
        .style("fill", "#000")
        .style("font-size", "14px")
        .style("text-transform", "capitalize")
        .text((d) => {
            var count = data.value.reduce(
                (accumulator, current) => accumulator + current[d],
                0
            );
            return `${d} (${count})`;
        });
}

const getScheduleData = async () => {
    toggleLoadingModal("show");

    return await axios({
        url: "/getTeacherDashboardData",
        method: "POST",
        timeout: 10000,
        data: {
            date: selectDate.value,
            uid: props.uid,
        },
    });
};
</script>

<template>
    <div class="d-flex justify-content-center">
        <div class="mx-2">
            <select
                v-if="!props.uid"
                v-model="teacherSelected"
                class="form-select form-select-sm"
            >
                <option v-for="teacher in teacherOptions" :value="teacher.uid">
                    {{ teacher.name }}
                </option>
            </select>
        </div>
        <div class="mx-2">
            <input
                type="text"
                class="form-control form-control-sm"
                ref="selectMonth"
                placeholder="Select month"
                readonly
                value=""
            />
        </div>
    </div>
    <div class="chart"></div>
    <div class="mt-5 px-4">
        <div class="table-responsive">
            <table class="table text-capitalize">
                <thead>
                    <tr>
                        <th scope="col">No</th>
                        <th scope="col">Start time</th>
                        <th scope="col">Duration(Mins)</th>
                        <th scope="col">Mode</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Title</th>
                        <th scope="col">Teachers</th>
                        <th scope="col">Students</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(list, index) in lists" :key="index">
                        <th scope="row">{{ ++index }}</th>
                        <td>{{ list.start_time }}</td>
                        <td>{{ list.duration }}</td>
                        <td>{{ list.mode }}</td>
                        <td>{{ list.type }}</td>
                        <td>{{ list.status }}</td>
                        <td>{{ list.title }}</td>
                        <td>{{ list.teachers.join(", ") }}</td>
                        <td>{{ list.students.join(", ") }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.chart {
    width: 100%;
    min-width: 300px;
    margin: auto;
}
</style>
