<script setup>
import { ref, watch, onMounted, watchEffect } from "vue";

const props = defineProps(["scheduleData", "gmt"]);
const scheduleForm = ref();
const formLessonStart = ref();
const changeTotal = ref(0);
const balanceTotal = ref(0);

onMounted(() => {
    formLessonStart.value = new AirDatepicker(formLessonStart.value, {
        locale: AirDatepickerLocaleEn,
        multipleDates: false,
        position: "bottom center",
        dateFormat: "yyyy-MM-dd",
        timepicker: true,
        timeFormat: "HH:mm",
        classes: "airDatepicker",
        autoClose: false,
        container: "#scheduleContentModalToggle",
        hoursStep: 1,
        minutesStep: 30,
        onSelect: ({ date, formattedDate, datepicker }) => {
            props.scheduleData.start = formattedDate + ":00";
        },
        selectedDates: props.scheduleData.start,
    });

    changeTotal.value = props.scheduleData.priceData.total;
});

const calculateChangeTotal = () => {
    changeTotal.value =
        (props.scheduleData.priceData.price * props.scheduleData.duration) / 30;
    balanceTotal.value = changeTotal.value - props.scheduleData.priceData.total;
};

const nubmerFormat = (number) => {
    return new Intl.NumberFormat().format(number);
};
</script>
<template>
    <div class="row" ref="scheduleForm">
        <div class="col-12">
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.status") }}:
                </div>
                <div class="me-2 px-2 fw-bold bg-secondary-subtle rounded">
                    <div class="schedule-confirm">
                        {{ $t(`booking.status.${props.scheduleData?.status}`) }}
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.time") }}:
                </div>
                <div class="me-2 fw-bold px-2 bg-danger-subtle rounded">
                    <input
                        type="text"
                        ref="formLessonStart"
                        class="form-control form-control-sm form-lesson-start text-dark"
                        required
                    />
                </div>
                <div class="ms-2 fw-bold small">
                    {{ `(GMT${props.gmt})` }}
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.duration") }}:
                </div>
                <div class="ms-2 fw-bold px-2 bg-danger-subtle rounded">
                    <input
                        type="number"
                        class="form-control form-control-sm text-dark"
                        v-model="props.scheduleData.duration"
                        min="30"
                        step="30"
                        required
                        @change="calculateChangeTotal"
                    />
                </div>
                <span class="fw-bold">{{ $t("booking.time.type") }}</span>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.mode") }}:
                </div>
                <div
                    class="me-2 px-2 bg-secondary-subtle rounded text-capitalize"
                >
                    {{
                        `${props.scheduleData.type} (${props.scheduleData.mode})`
                    }}
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.teacher") }}:
                </div>
                <div class="me-2 px-2 bg-secondary-subtle rounded">
                    <a
                        v-for="teacher in props.scheduleData.data.teachers"
                        :key="teacher"
                        :href="teacher.link"
                        target="_blank"
                        >{{ teacher.name }}</a
                    >
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="me-3 fw-bold fs-5">
                    {{ $t("booking.booking.form.student") }}:
                </div>
                <div>
                    <div
                        v-for="student in props.scheduleData.data.students"
                        class="me-2 my-1 px-2 bg-secondary-subtle rounded d-inline-block"
                    >
                        {{ student.name }}
                    </div>
                    <div>
                        {{ $t("booking.point_area.student.points") }}:
                        {{ nubmerFormat(props.scheduleData.studentPoint) }}
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap align-items-center mb-2">
                <div class="row w-100">
                    <div class="col-12 col-md-5">
                        <div class="w-100 h-100 border border-3 rounded p-2">
                            <div class="fw-bold">
                                {{ $t("booking.point_area.title.original") }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.lesson") }}:
                                {{ props.scheduleData.priceData.lessson }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.point") }}:
                                {{
                                    `${nubmerFormat(
                                        props.scheduleData.priceData.price
                                    )} / ${$t("booking.point_area.lesson")}`
                                }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.total") }}:
                                {{
                                    nubmerFormat(
                                        props.scheduleData.priceData.total
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-2 d-flex align-items-center justify-content-center py-2"
                    >
                        <i
                            class="fa-solid fa-arrow-right d-none d-md-block"
                        ></i>
                        <i class="fa-solid fa-arrow-down d-block d-md-none"></i>
                    </div>
                    <div class="col-12 col-md-5">
                        <div
                            class="w-100 h-100 border border-3 border-danger-subtle rounded p-2"
                        >
                            <div class="fw-bold">
                                {{ $t("booking.point_area.title.change") }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.lesson") }}:
                                {{ props.scheduleData.duration / 30 }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.point") }}:
                                {{
                                    `${nubmerFormat(
                                        props.scheduleData.priceData.price
                                    )} / ${$t("booking.point_area.lesson")}`
                                }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.total") }}:
                                {{ nubmerFormat(changeTotal) }}
                            </div>
                            <div>
                                {{ $t("booking.point_area.balance") }}:
                                <span
                                    :class="{ 'text-danger': balanceTotal < 0 }"
                                >
                                    {{ nubmerFormat(balanceTotal) }}</span
                                >
                                <span class="text-danger fw-bold ms-2">
                                    {{
                                        props.scheduleData.studentPoint -
                                            balanceTotal <
                                        0
                                            ? $t(
                                                  "booking.point_area.student.point_not_enough"
                                              )
                                            : ""
                                    }}</span
                                >
                            </div>
                            <div class="small text-danger">
                                {{
                                    balanceTotal == 0
                                        ? ""
                                        : balanceTotal < 0
                                        ? $t(
                                              "booking.point_area.message.return"
                                          )
                                        : $t(
                                              "booking.point_area.message.deducted"
                                          )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
