<script setup>
import axios from "axios";
import { onMounted, onUnmounted, ref, watch, watchEffect } from "vue";

// check login
const props = defineProps({
    auth: Number,
    available_start: Number,
    available_end: Number,
});

const timeZone = ref("");
const gmt = ref("");
const nowDate = ref(moment().format("YYYY-MM-DD"));
const currentDate = ref(moment().format("YYYY-MM-DD"));
// const nowDate = ref(
//     moment()
//         .add(props?.available_start ?? 24, "hours")
//         .format("YYYY-MM-DD")
// );
// const currentDate = ref(
//     moment().add(props.available_start, "hours").format("YYYY-MM-DD")
// );
const maxDate = ref(
    moment()
        .add(props?.available_end ?? 2160, "hours")
        .format("YYYY-MM-DD")
);
const scheduleDataRef = ref({
    timezone: "",
    range: {
        start: "",
        end: "",
    },
    available: [],
    booked: [],
    price: 0,
});
const currentUid = ref(0);
const currentProductUuid = ref(null);
const scheduleSelectedDataRef = ref({});
const datesRef = ref([]);
const changeDateBtn = ref(null);
const fetchTeacherTimeUrls = ref({
    times: "",
});
const deleteToCartBtn = ref();
const addToCartBtn = ref();

watch(currentDate, async (newValue, oldValue) => {
    if (newValue >= moment().format("YYYY-MM-DD")) {
        fetchData();
    }
});

watch(
    fetchTeacherTimeUrls,
    async (newValue, oldValue) => {
        fetchData();
    },
    {
        deep: true,
    }
);

watch(
    scheduleSelectedDataRef,
    async (newValue, oldValue) => {
        if (addToCartBtn.value?.classList.contains("disabled")) {
            addToCartBtn.value.classList.remove("disabled");
        }
    },
    {
        deep: true,
    }
);

onMounted(async () => {
    timeZone.value =
        document.documentElement.getAttribute("timezone") ?? "Asia/Taipei";
    gmt.value = document.documentElement.getAttribute("gmt") ?? "+08:00";
    nowDate.value = moment().format("YYYY-MM-DD");
    currentDate.value = moment().format("YYYY-MM-DD");
    maxDate.value = moment()
        .add(props?.available_end ?? 2160, "hours")
        .format("YYYY-MM-DD");
    fetchData();
    document.addEventListener("PostDataVueEvent", postHandle);
    await import("@/scripts/cart.js");
    moment.locale(
        document.documentElement.lang === "zh"
            ? "zh-tw"
            : document.documentElement.lang
    );
});

onUnmounted(() => {
    document.removeEventListener("PostDataVueEvent", postHandle);
});

const convertTimezoneWithTime = (datetime, format = "YYYY-MM-DD") => {
    return convertTimezone(moment(datetime)).format(format);
};

const convertTimezone = (datetime) => {
    return datetime.zone(gmt.value);
};

const convertOriginalTimezone = (datetime, format = "YYYY-MM-DD") => {
    return moment(datetime).zone("+08:00").format(format);
};

const postHandle = (event) => {
    if (event.detail.type == "update") {
        fetchTeacherTimeUrls.value = event.detail.data;
        currentDate.value = nowDate.value;
    }
};

const fetchData = () => {
    if (!fetchTeacherTimeUrls.value?.times) {
        return;
    }

    changeDateBtn.value?.classList.add("disabled");

    axios({
        method: "post",
        url: fetchTeacherTimeUrls.value.times,
        timeout: 5000,
        data: {
            timezone: timeZone.value,
            date: currentDate.value,
        },
    })
        .then(function (response) {
            // reset
            datesRef.value = [];

            scheduleDataRef.value.timezone = response.data.timeZone;
            scheduleDataRef.value.range = response.data.rangeTimes;
            scheduleDataRef.value.available = response.data.availableTimes;
            scheduleDataRef.value.booked = response.data.bookedTimes;
            scheduleDataRef.value.price = response.data.price;

            currentUid.value = response.data.uid;
            currentProductUuid.value = response.data.productUuid;

            if (response.data.selectedTimes.length) {
                scheduleSelectedDataRef.value[response.data.uid] =
                    response.data.selectedTimes;
            }

            let startDate = convertTimezone(
                moment(scheduleDataRef.value.range.start)
            );
            let endDate = convertTimezone(
                moment(scheduleDataRef.value.range.end)
            );

            while (startDate <= endDate) {
                datesRef.value.push({
                    date: startDate.format("YYYY-MM-DD"),
                    display: startDate.format("DD"),
                    weekday: startDate.format("ddd"),
                    times: scheduleDataRef.value.available
                        .filter((item) => {
                            return (
                                startDate.format("YYYY-MM-DD") ==
                                convertTimezone(moment(item)).format(
                                    "YYYY-MM-DD"
                                )
                            );
                        })
                        .map((item) => {
                            return {
                                original: item,
                                display: convertTimezone(moment(item)).format(
                                    "HH:mm"
                                ),
                            };
                        }),
                });

                startDate = moment(startDate).add(1, "days");
            }

            btnToggleDisabled();
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            console.log(error.config);

            btnToggleDisabled();
        });
};

const btnToggleDisabled = () => {
    if (
        (changeDateBtn.value?.dataset.type === "previous" &&
            nowDate.value === currentDate.value) ||
        (changeDateBtn.value?.dataset.type === "next" &&
            nowDate.value >= maxDate.value)
    ) {
        return;
    }

    changeDateBtn.value?.classList.toggle("disabled");
};

const toChangeDate = (event, mode = "") => {
    changeDateBtn.value = event.target.closest(".btn");
    let todayDate = nowDate.value;
    let changeDate = nowDate.value;

    if (changeDateBtn.value.classList.contains("btn-secondary")) {
        return;
    }

    switch (mode) {
        case "today":
            currentDate.value = changeDate;
            break;
        case "previous":
            changeDate = moment(currentDate.value)
                .subtract(1, "weeks")
                .format("YYYY-MM-DD");

            changeDate = changeDate >= todayDate ? changeDate : todayDate;
            currentDate.value = changeDate;
            break;
        case "next":
            changeDate = moment(currentDate.value)
                .add(1, "weeks")
                .format("YYYY-MM-DD");

            changeDate = changeDate >= todayDate ? changeDate : todayDate;
            currentDate.value = changeDate;
            break;
        default:
            currentDate.value = changeDate;
            break;
    }
};

const selectedDate = (event, selectTime) => {
    if (!props.auth || event.target?.classList.contains("booked")) {
        return;
    }

    if (!scheduleSelectedDataRef.value.hasOwnProperty(currentUid.value)) {
        scheduleSelectedDataRef.value[currentUid.value] = [];
    }

    if (!scheduleSelectedDataRef.value[currentUid.value].includes(selectTime)) {
        scheduleSelectedDataRef.value[currentUid.value].push(selectTime);
    } else {
        scheduleSelectedDataRef.value[currentUid.value].splice(
            scheduleSelectedDataRef.value[currentUid.value].findIndex(
                (item) => selectTime === item
            ),
            1
        );
    }

    scheduleSelectedDataRef.value[currentUid.value] =
        scheduleSelectedDataRef.value[currentUid.value].toSorted();
};

const clearBooking = (event) => {
    if (scheduleSelectedDataRef.value.hasOwnProperty(currentUid.value)) {
        delete scheduleSelectedDataRef.value[currentUid.value];
    }
};

const numberFormat = (nubmer) => {
    return String(nubmer).replace(/(.)(?=(\d{3})+$)/g, "$1,");
};
</script>

<template>
    <div class="card rounded-3 border-2 p-3 teachers-times-element">
        <div class="row mb-3">
            <div class="col-12">
                <div class="tacher-schedule">
                    <div class="schedule-header mb-3">
                        <div
                            class="d-flex flex-column flex-md-row justify-content-start"
                        >
                            <div class="schedule-buttons me-2">
                                <button
                                    type="button"
                                    :class="{
                                        btn: true,
                                        'btn-sm': true,
                                        'btn-outline-primary': true,
                                        'me-2': true,
                                        'me-md-3': true,
                                    }"
                                    data-type="today"
                                    @click="toChangeDate($event, 'today')"
                                >
                                    {{ $t("booking.button.today") }}
                                </button>
                                <button
                                    type="button"
                                    :class="{
                                        btn: true,
                                        'btn-sm': true,
                                        'me-1': true,
                                        'me-md-2': true,
                                        'btn-outline-primary':
                                            nowDate !== currentDate,
                                        'btn-secondary':
                                            nowDate === currentDate,
                                        disabled: nowDate === currentDate,
                                    }"
                                    data-type="previous"
                                    @click="toChangeDate($event, 'previous')"
                                >
                                    <i class="fa-solid fa-chevron-left"></i>
                                </button>
                                <button
                                    type="button"
                                    :class="{
                                        btn: true,
                                        'btn-sm': true,
                                        'me-1': true,
                                        'me-md-2': true,
                                        'btn-outline-primary':
                                            currentDate < maxDate,
                                        'btn-secondary': currentDate >= maxDate,
                                        disabled: nowDate >= maxDate,
                                    }"
                                    data-type="next"
                                    @click="toChangeDate($event, 'next')"
                                >
                                    <i class="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                            <div class="schedule-time fs-5 fw-bold my-auto">
                                {{
                                    convertTimezoneWithTime(
                                        scheduleDataRef.range.start
                                    )
                                }}
                                -
                                {{
                                    convertTimezoneWithTime(
                                        scheduleDataRef.range.end
                                    )
                                }}
                            </div>
                            <div class="fw-bold small ms-auto my-auto">
                                {{ `(GMT${gmt})` }}
                            </div>
                        </div>
                    </div>
                    <div class="schedule-body">
                        <div class="d-flex justify-content-start">
                            <div
                                v-for="(item, index) in datesRef"
                                :key="index"
                                class="schedule-date text-center border-5 border-top border-primary"
                            >
                                <div class="my-1">
                                    <div>{{ item.weekday }}</div>
                                    <div>{{ item.display }}</div>
                                </div>

                                <div class="schedule-times">
                                    <div
                                        v-for="time in item.times"
                                        @click="
                                            selectedDate($event, time.original)
                                        "
                                        :class="{
                                            active: scheduleSelectedDataRef[
                                                currentUid
                                            ]?.includes(time.original),
                                            booked: scheduleDataRef.booked.includes(
                                                time.original
                                            ),
                                        }"
                                    >
                                        {{ time.display }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="auth" class="row border-top border-2 py-3 schedule-price">
            <div class="col-8">
                {{ numberFormat(scheduleDataRef.price) }}
                {{ $t("booking.price.points") }} x
                {{
                    numberFormat(
                        scheduleSelectedDataRef[currentUid]?.length ?? 0
                    )
                }}
                {{ $t("booking.unit") }}
            </div>
            <div class="col-4 text-end">
                {{
                    numberFormat(
                        scheduleDataRef.price *
                            (scheduleSelectedDataRef[currentUid]?.length ?? 0)
                    )
                }}
                {{ $t("booking.price.points") }}
            </div>
        </div>

        <div v-if="auth" class="row border-top border-2 pt-3">
            <div class="col-6">
                <button
                    type="button"
                    :class="{
                        btn: true,
                        'btn-outline-danger': true,
                        'w-100': true,
                        deleteToCartBtn: true,
                        disabled: !scheduleSelectedDataRef[currentUid]?.length,
                    }"
                    ref="deleteToCartBtn"
                    :data-uuid="currentProductUuid"
                    :data-teacher-id="currentUid"
                    @click="clearBooking($event)"
                >
                    {{ $t("booking.button.clear") }}
                </button>
            </div>
            <div class="col-6">
                <button
                    type="button"
                    :class="{
                        btn: true,
                        'btn-orange': true,
                        'w-100': true,
                        addToCartBtn: true,
                        disabled: !scheduleSelectedDataRef[currentUid]?.length,
                    }"
                    ref="addToCartBtn"
                    :data-uuid="currentProductUuid"
                    :data-qty="scheduleSelectedDataRef[currentUid]?.length ?? 0"
                    :data-teacher-id="currentUid"
                    :data-booking-times="scheduleSelectedDataRef[currentUid]"
                >
                    {{ $t("booking.button.addtocart") }} ({{
                        numberFormat(
                            scheduleSelectedDataRef[currentUid]?.length ?? 0
                        )
                    }})
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tacher-schedule {
    font-weight: 500;
    user-select: none;

    .schedule-body {
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .schedule-date {
        font-size: 14px;
        width: 75px;
        margin-left: 2px;
        margin-right: 2px;

        @media (min-width: 768px) {
            font-size: inherit;
            width: 80px;
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    .schedule-times {
        div {
            color: var(--bs-orange);
            border: 2px solid;
            border-color: transparent;
            padding-top: 3px;
            padding-bottom: 3px;
            margin-bottom: -2px;

            &:not(.booked) {
                cursor: pointer;
                &:hover,
                &.active {
                    border-color: var(--bs-orange);
                }
            }

            &.booked {
                color: var(--bs-gray-500);
            }
        }
    }
}

.schedule-price {
    font-size: 14px;

    @media (min-width: 768px) {
        font-size: 20px;
    }
}
</style>
