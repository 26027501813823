<script setup>
import axios from "axios";
import { onMounted, onUnmounted, ref, watch, watchEffect } from "vue";

const timeZone = ref("");
const gmt = ref("");
const scheduleDateUnit = ref("weeks");
const nowDate = ref(moment().format("YYYY-MM-DD"));
const currentDate = ref(moment().format("YYYY-MM-DD"));
const datesRef = ref([]);
const scheduleTimeTitle = ref();
const scheduleGMTTitle = ref();
const scheduleTimeDurationRef = ref([]);
const variety_clickDatas = ref([]);
const variety_array_clickDatas = ref([]);
const clickDatas = ref([[], [], [], [], [], [], []]);
const props = defineProps(["target_uid", "week_time", "extra_time"]);

watch(scheduleDateUnit, async (newValue, oldValue) => {
    getDateRange();
});

onMounted(async () => {
    props.week_time.forEach((weekDay, index) => {
        weekDay.forEach((item) => {
            clickDatas.value[index].push(item);
        });
    });

    Object.keys(props.extra_time).forEach((times, index) => {
        variety_clickDatas.value[times] = [];
        props.extra_time[times].forEach((time) => {
            variety_clickDatas.value[times].push(time);
        });
    });

    timeZone.value =
        document.documentElement.getAttribute("timezone") ?? "Asia/Taipei";
    gmt.value = document.documentElement.getAttribute("gmt") ?? "+08:00";
    nowDate.value = convertTimezone(moment()).format("YYYY-MM-DD");
    currentDate.value = convertTimezone(moment()).format("YYYY-MM-DD");
    moment.locale(
        document.documentElement.lang === "zh"
            ? "zh-tw"
            : document.documentElement.lang
    );
    getDateRange();
    createScheduleTimeDuration();
    displayScheduleTimeTitle();
});

const convertTimezone = (datetime) => {
    return datetime.zone(gmt.value);
};

const createScheduleTimeDuration = () => {
    let startTime = moment().startOf("day");
    let endTime = moment().endOf("day");
    while (startTime <= endTime) {
        scheduleTimeDurationRef.value.push(startTime.format("HH:mm"));
        startTime = startTime.add(30, "minutes");
    }
};

const getDateRange = () => {
    datesRef.value = [];
    let startDate = moment(currentDate.value).startOf(scheduleDateUnit.value);
    let endDate = moment(currentDate.value).endOf(scheduleDateUnit.value);

    if (scheduleDateUnit.value === "months") {
        startDate = moment(startDate).startOf("weeks");
        endDate = moment(endDate).endOf("weeks");
    }

    while (startDate < endDate) {
        datesRef.value.push({
            date: startDate.format("YYYY-MM-DD"),
            display: startDate.format("DD"),
            weekday: startDate.format("ddd"),
        });

        startDate = moment(startDate).add(1, "days");
    }

    displayScheduleTimeTitle();
};

const changeDate = (event) => {
    let mode = event.target.closest(".btn").dataset.mode;

    switch (mode) {
        case "previous":
            currentDate.value = moment(currentDate.value)
                .subtract(1, scheduleDateUnit.value)
                .format("YYYY-MM-DD");
            break;
        case "next":
            currentDate.value = moment(currentDate.value)
                .add(1, scheduleDateUnit.value)
                .format("YYYY-MM-DD");
            break;
        default:
            currentDate.value = moment().format("YYYY-MM-DD");
            break;
    }

    getDateRange();
};

/* -- Default schedule button */

const clickClear = () => {
    clickDatas.value = [[], [], [], [], [], [], []];
};

const clickSubmit = async () => {
    clickDatas.value.forEach((weekDay) => {
        if (!weekDay.length == 0) {
            weekDay.forEach((time) => {
                var hour = time.start.split(":")[0];
                var min = time.start.split(":")[1];
                if (min == "30") {
                    min = "00";
                    hour = (parseInt(hour) + 1).toString().padStart(2, "0");
                } else {
                    min = "30";
                }
                time.end = hour + ":" + min;
            });
        }
    });

    window.loadingModal;
    loadingModal.show();
    window.customModal;

    await axios({
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": document
                .getElementsByName("csrf-token")[0]
                .getAttribute("content"),
        },
        url: "/updateUserProfile",
        method: "post",
        timeout: 10000,
        data: {
            time_zone: timeZone.value,
            work_time: JSON.stringify(clickDatas.value),
            target_uid: props.target_uid.toString(),
            form_type: "userSettingTeacherSchedules",
        },
    })
        .then(function (response) {
            //scheduleData.value = response.data;
            loadingModal.hide();
            window.customModal._element.querySelector(
                ".modal-header"
            ).innerHTML = "Success";
            window.customModal._element.querySelector(".modal-body").innerHTML =
                response.data.message;

            var closeBtn = document.createElement("button");
            closeBtn.classList.add("btn", "btn-primary");
            closeBtn.innerText = "OK";
            closeBtn.setAttribute("data-bs-dismiss", "modal");
            window.customModal._element
                .querySelector(".modal-footer")
                .append(closeBtn);
            customModal.show();
            setTimeout(() => {
                loadingModal.hide();
            }, 500);
        })
        .catch(function (error) {
            loadingModal.hide();
            window.customModal._element.querySelector(
                ".modal-header"
            ).innerHTML = "Error";
            window.customModal._element.querySelector(".modal-body").innerHTML =
                "❌" + error;

            var closeBtn = document.createElement("button");
            closeBtn.classList.add("btn", "btn-primary");
            closeBtn.innerText = "OK";
            closeBtn.setAttribute("data-bs-dismiss", "modal");
            window.customModal._element
                .querySelector(".modal-footer")
                .append(closeBtn);
            customModal.show();
            setTimeout(() => {
                loadingModal.hide();
            }, 500);
        });
};

const clickDays = (timeDuration, weekday, index, id) => {
    if (!clickDatas.value[index]) {
        clickDatas.value[index] = [];
    }

    var findIndex = clickDatas.value[index].findIndex(
        (item) => item.start === timeDuration
    );

    if (findIndex > -1) {
        clickDatas.value[index].splice(findIndex, 1);
    } else {
        clickDatas.value[index].push({ start: timeDuration, end: "" });
    }
};

/* -- Variety schedule button */
const displayScheduleTimeTitle = () => {
    let start = datesRef.value.at(0);
    let end = datesRef.value.at(-1);
    let displayDate = start.date;

    switch (scheduleDateUnit.value) {
        case "days":
            displayDate = start.date;
            break;
        case "weeks":
            displayDate = `${start.date} - ${end.date}`;
            break;
        case "months":
            displayDate = moment(currentDate.value).format("YYYY-MM");
            break;
        default:
            displayDate = start.date;
    }

    scheduleTimeTitle.value.innerHTML = displayDate;
    scheduleGMTTitle.value.innerHTML = `(GMT${gmt.value})`;
};

const variety_clickClear = () => {
    variety_clickDatas.value = [];
};

const variety_clickSubmit = async () => {
    Object.keys(variety_clickDatas.value).forEach((date, index) => {
        variety_array_clickDatas.value[index] = [];
        variety_array_clickDatas.value[index].push({ date: date });
        if (!variety_clickDatas.value[date].length == 0) {
            variety_clickDatas.value[date].forEach((time) => {
                var hour = time.start.split(":")[0];
                var min = time.start.split(":")[1];
                if (min == "30") {
                    min = "00";
                    hour = (parseInt(hour) + 1).toString().padStart(2, "0");
                } else {
                    min = "30";
                }
                time.end = hour + ":" + min;
                variety_array_clickDatas.value[index].push({
                    start: time.start,
                    end: time.end,
                });
            });
        }
    });

    window.loadingModal;
    loadingModal.show();
    window.customModal;

    await axios({
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": document
                .getElementsByName("csrf-token")[0]
                .getAttribute("content"),
        },
        url: "/updateUserProfile",
        method: "post",
        timeout: 10000,
        data: {
            time_zone: timeZone.value,
            work_time: JSON.stringify(variety_array_clickDatas.value),
            target_uid: props.target_uid.toString(),
            form_type: "userSettingTeacherVarietySchedules",
        },
    })
        .then(function (response) {
            loadingModal.hide();
            window.customModal._element.querySelector(
                ".modal-header"
            ).innerHTML = "Success";
            window.customModal._element.querySelector(".modal-body").innerHTML =
                response.data.message;

            var closeBtn = document.createElement("button");
            closeBtn.classList.add("btn", "btn-primary");
            closeBtn.innerText = "OK";
            closeBtn.setAttribute("data-bs-dismiss", "modal");
            window.customModal._element
                .querySelector(".modal-footer")
                .append(closeBtn);
            customModal.show();
            setTimeout(() => {
                loadingModal.hide();
            }, 500);
        })
        .catch(function (error) {
            loadingModal.hide();
            window.customModal._element.querySelector(
                ".modal-header"
            ).innerHTML = "Error";
            window.customModal._element.querySelector(".modal-body").innerHTML =
                "❌" + error;

            var closeBtn = document.createElement("button");
            closeBtn.classList.add("btn", "btn-primary");
            closeBtn.innerText = "OK";
            closeBtn.setAttribute("data-bs-dismiss", "modal");
            window.customModal._element
                .querySelector(".modal-footer")
                .append(closeBtn);
            customModal.show();
            setTimeout(() => {
                loadingModal.hide();
            }, 500);
        });
};

const variety_clickDays = (timeDuration, start, date, weekday, index, id) => {
    if (!variety_clickDatas.value[date]) {
        variety_clickDatas.value[date] = [];
    }

    var findIndex = variety_clickDatas.value[date].findIndex(
        (item) => item.start === timeDuration
    );
    if (findIndex > -1) {
        variety_clickDatas.value[date].splice(findIndex, 1);
        if (variety_clickDatas.value[date].length == 0) {
            delete variety_clickDatas.value[date];
        }
    } else {
        variety_clickDatas.value[date].push({ start: timeDuration, end: "" });
    }
};

const variety_otherColor = (date, timeDuration) => {
    if (variety_clickDatas.value[date]) {
        if (
            variety_clickDatas.value[date].findIndex(
                (item) => item.start == timeDuration
            ) > -1
        ) {
            return true;
        }
    }
    return false;
};

const day_off_otherColor = (date) => {
    // -- true 灰色  false 紅色
    if (!variety_clickDatas.value[date]) {
        return true;
    }

    if (Object.keys(variety_clickDatas.value[date]).length > 0) {
        console.log();
        if (!variety_clickDatas.value[date][0].hasOwnProperty("start")) {
            return false;
        }

        return true;
    }
};

const day_off = (date) => {
    // -- 如果 day_off
    if (!variety_clickDatas.value[date]) {
        variety_clickDatas.value[date] = [];
    }
    // -- 如果要取消 day_off
    else {
        delete variety_clickDatas.value[date];
    }
};
</script>

<template>
    <div
        class="schedule-element border border-2 p-3"
        :data-mode="scheduleDateUnit"
    >
        <div class="row mb-3">
            <div class="user-schedule">
                <div class="text-center mb-3">
                    <h2 class="mb-0">{{ $t("schedules.default.title") }}</h2>
                    <small>{{ $t("schedules.default.subtitle") }}</small>
                    <div class="small fw-bold text-center">
                        {{ `(GMT${gmt})` }}
                    </div>
                </div>
                <div class="d-flex justify-content-between bd-highlight mb-3">
                    <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        v-on:click="clickClear()"
                    >
                        {{ $t("schedules.button.default.clear") }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        v-on:click="clickSubmit()"
                    >
                        {{ $t("schedules.button.default.submit") }}
                    </button>
                </div>
                <div class="schedule-main">
                    <div class="schedule-header schedule-row">
                        <div class="schedule-time">Time</div>
                        <div v-for="(item, index) in datesRef" :key="index">
                            <div
                                :class="{
                                    'now-time': item.date == nowDate,
                                }"
                            >
                                <div>{{ item.weekday }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="schedule-body">
                        <div class="schedule-row schedule-container">
                            <div class="schedule-time"></div>
                            <div
                                v-for="(item, index) in datesRef"
                                :key="index"
                            ></div>
                        </div>
                        <div
                            v-for="(
                                timeDuration, index
                            ) in scheduleTimeDurationRef"
                            :key="index"
                            class="schedule-row"
                        >
                            <div
                                class="schedule-time"
                                :data-duration-time="timeDuration"
                            ></div>
                            <button
                                :class="{
                                    otherColor:
                                        clickDatas[index].findIndex(
                                            (item) => item.start == timeDuration
                                        ) > -1,
                                }"
                                type="button"
                                class="btn btn-outline-light border border-dark"
                                v-for="(item, index) in datesRef"
                                :key="index"
                                v-on:click="
                                    clickDays(
                                        timeDuration,
                                        item.weekday,
                                        index,
                                        index + '-' + timeDuration
                                    )
                                "
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <hr />
    <div
        class="schedule-element border border-2 p-3"
        :data-mode="scheduleDateUnit"
    >
        <div class="row mb-3">
            <div class="user-schedule">
                <div class="text-center mb-3">
                    <h2 class="mb-0">{{ $t("schedules.adjusted.title") }}</h2>
                    <small>{{ $t("schedules.adjusted.subtitle") }}</small>
                </div>
                <div
                    class="d-flex justify-content-between align-items-center bd-highlight mb-3"
                >
                    <!-- <button 
                        type="button" class="btn btn-sm btn-danger"
                        v-on:click="variety_clickClear()"
                        >{{ $t("schedules.button.adjusted.clear") }}</button> -->
                    <button
                        type="button"
                        class="btn btn-sm btn-link blank_space disabled"
                    >
                        {{ $t("schedules.button.adjusted.clear") }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-primary"
                        data-mode="previous"
                        @click="changeDate($event)"
                    >
                        <i class="fa-solid fa-chevron-left"></i>
                    </button>
                    <div class="my-auto">
                        <div class="fs-5 fw-bold" ref="scheduleTimeTitle"></div>
                        <div
                            class="small fw-bold text-center"
                            ref="scheduleGMTTitle"
                        ></div>
                    </div>
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-primary"
                        data-mode="next"
                        @click="changeDate($event)"
                    >
                        <i class="fa-solid fa-chevron-right"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        v-on:click="variety_clickSubmit()"
                    >
                        {{ $t("schedules.button.adjusted.submit") }}
                    </button>
                </div>
                <div class="schedule-main">
                    <div class="schedule-header schedule-row">
                        <div class="schedule-time">Time</div>
                        <div
                            v-for="(item, index) in datesRef"
                            :key="index"
                            class="pb-1"
                        >
                            <div
                                :class="{
                                    'now-time': item.date == nowDate,
                                }"
                            >
                                <div>{{ item.weekday }}-{{ item.display }}</div>

                                <button
                                    :class="{
                                        dayOffColor: day_off_otherColor(
                                            item.date
                                        ),
                                    }"
                                    type="button"
                                    class="btn btn-danger btn-sm closed_day"
                                    v-on:click="day_off(item.date)"
                                >
                                    {{ $t("schedules.button.day_off") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="schedule-body">
                        <div class="schedule-row schedule-container">
                            <div class="schedule-time"></div>
                            <div
                                v-for="(item, index) in datesRef"
                                :key="index"
                            ></div>
                        </div>
                        <div
                            v-for="(
                                timeDuration, index
                            ) in scheduleTimeDurationRef"
                            :key="index"
                            class="schedule-row"
                        >
                            <div
                                class="schedule-time"
                                :data-duration-time="timeDuration"
                            ></div>
                            <button
                                :class="{
                                    otherColor: variety_otherColor(
                                        item.date,
                                        timeDuration
                                    ),
                                }"
                                type="button"
                                class="btn btn-outline-light border border-dark"
                                v-for="(item, index) in datesRef"
                                :key="item.date"
                                v-on:click="
                                    variety_clickDays(
                                        timeDuration,
                                        item.start,
                                        item.date,
                                        item.weekday,
                                        index,
                                        item.date + '=' + timeDuration
                                    )
                                "
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.schedule-element {
    user-select: none;

    .blank_space {
        color: #fff;
    }

    .closed_day {
        font-size: 12px;
    }

    .dayOffColor {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }

    .title {
        display: flex;
        justify-content: space-evenly;
    }

    .schedule-row {
        display: grid;

        div {
            word-wrap: break-word;
            word-break: normal;
        }

        > div:not(.schedule-time) {
            border-left: 1px solid;
        }
    }

    &[data-mode="days"] {
        .schedule-row {
            grid-template-columns: 47px 1fr;
            margin-top: -1px;

            &:not(.schedule-header) {
                grid-template-rows: 30px;
            }
        }
    }

    &[data-mode="weeks"] {
        .schedule-row {
            grid-template-columns: 47px repeat(7, minmax(50px, 1fr));
            margin-top: -1px;

            &:not(.schedule-header) {
                grid-template-rows: 18px;
            }
        }
    }

    &[data-mode="months"] {
        .schedule-row {
            grid-template-columns: repeat(7, minmax(50px, 1fr));
            margin-top: -1px;

            &:not(.schedule-header) {
                grid-template-rows: minmax(50px, 100px);
            }
        }
    }

    .schedule-header {
        border-bottom: 1px solid var(--bs-tertiary-color);
        align-items: end;
        font-size: 14px;

        > div:not(.schedule-time) {
            > div {
                //border: 1px solid;
                font-size: 18px;
                width: 80%;
                height: 50px;
                border-radius: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;
                margin: 5px;
                font-weight: bold;

                &.now-time {
                    border-color: var(--color-blue-1);
                    border-width: 2px;
                    color: var(--color-blue-1);
                }

                > div:nth-of-type(2) {
                    font-size: 16px;
                }
            }
        }
    }

    .schedule-body {
        position: relative;

        .otherColor {
            background-color: #beeebe; // whatever background color you want.
        }

        .schedule-row {
            border-bottom: 1px solid var(--bs-tertiary-color);

            &:hover {
                background-color: var(--bs-light-border-subtle) !important;
            }

            &.schedule-container {
                //position: absolute;
                background-color: transparent;
                grid-template-rows: auto;
                width: 100%;
                height: 100%;
            }

            .schedule-time {
                &:not([data-duration-time="00:00"]) {
                    position: relative;

                    &::before {
                        content: attr(data-duration-time);
                        display: block;
                        position: absolute;
                        bottom: calc(100% - 10px);
                        background-color: white;
                        width: 100%;
                        text-align: justify;
                        font-size: 14px;
                    }

                    margin-right: 5px;
                }
            }
        }
    }
}
</style>
